    .utility-nav {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
    }
    .utility-nav__link {
      color: $dark-gray;
      font-size: 14px;
      font-weight: 600;
      margin: 0 8px;
    }
