.page-header,
.media-selection-header {
  .page-actions {
    @include group;
    @include no-bullet;
    margin: 0 0 0 auto;
    > li {
      float: left;
      margin-right: 10px;
      &:last-child {
        margin: 0;
      }
      > span {
        display: inline-block;
        font-size: 14px;
        line-height: 14px;
        margin: 11px 0;
      }
    }
    li.filters {
      margin-right: 7px; margin-left: -7px;
      > a {
        color: $medium-gray;
        height: 36px;
        padding: 4px 10px 0;
        position: relative;
        z-index: 9;
        @include default-property-transition(color);
        &:hover {
          color: $link-hover;
          svg {
            fill: $link-hover;
          }
        }
        &.applied {
          color: $green;
        }
      }
      > svg {
        margin: 7px 10px 10px;

        &.icon {
          width: 20px;
          height: 20px;
        }

        &.icon-Close {
          padding: 2px;
        }
      }
      > .clear-filters {
        height: inherit;
        padding: 0 3px 0 0;
        vertical-align: top;
        .rh-glyphs-close {
          position: relative;
          color: $green;
          font-size: 14px;
          padding-right: 2px;
          top: inherit !important;
          right: inherit !important;
        }
      }
      .form-rh {
        top: 33px;
        width: 372px;
        z-index: 8;
        fieldset {
          width: 100%;
        }
        header {
          font-size: 20px;
          line-height: 20px;
          padding: 12px 12px 6px;
        }
        dl {
          padding: 0 12px 1px;
          dt,
          dd {
            width: 100%;
          }
          dt {
            color: $medium-gray;
            float: none;
            margin: 0;
            padding: 10px 0;
          }
          dd {
            margin-bottom: 10px;
            &.cbs {
              margin-top: -10px;
            }
            &.filters__filter-container--groupBy {
              margin-top: 0;
            }
            .select {
              margin: 10px 0 0;
              select {
                margin: 0;
              }
            }
          }
        }
        .cb {
          margin: 0;
          padding-bottom: 0;
          width: 50%;

          label {
            @include text-ellipsis;
            padding-right: 10px;
            width: 100%;
          }
        }
        #settings & {
          width: 242px;
          dl {
            padding: 12px;
            dt {
              padding: 0;
            }
            dd {
              margin: 0;
            }
          }
          .cb {
            width: auto;
          }
        }
      }
    }
  }
}
.submenu {
  dd {
    width: 100% !important;

    .all-categories {
      float: none;
      width: 100% !important;
    }
    .add-category {
      width: 50%;
    }

    .rh-select-block {
      margin: 0;
    }
    .airport-search-block {
      position: relative;
      margin: 0;

      .form-rh {
        width: 100% !important;
      }
      .fs-form,
      .fs-input-location {
        height: 36px;
      }
      .rh-airport-search-close {
        display: block;
      }
    }
    .cb__group-by-category {
      margin-left: 10px !important;
      width: calc(100% - 10px) !important;
    }
  }
}
.submenu__actions {
  padding: 10px;
}
.submenu__action {
  width: 100%;
}
#airline-and-upa-category-filter {
  margin-bottom: 0;
  padding-right: 0 !important;

  .filter-search {
    position: relative;
    float: left;
    margin-bottom: 10px;
    padding-right: 12px;
    width: 50%;
  }

  .filter-items {
    max-height: 260px; // 10 items x 26px height per item (16px line height + 10px margin bottom)
    overflow: auto;

    .none {
      text-align: center;
      color: #8e949b; // $medium-gray
      padding: 30px 12px;
    }
  }

  .rh-glyphs-down-arrow {
    left: 150px;
  }

  .rh-glyphs-close {
    position: absolute;
    top: 10px;
    left: 143px;
  }
}

#upas .page-header .page-actions li.filters .form-rh dl {
  padding-bottom: 0;
  padding-right: 0;

  dt, dd {
    padding-right: 12px;
  }
}

#upa-categories-filter {
  margin-bottom: 0;
  padding-right: 0 !important;

  .filter-search {
    margin-bottom: 10px;
  }

  .filter-items {
    max-height: 260px; // 10 items x 26px height per item (16px line height + 10px margin bottom)
    overflow: auto;

    .none {
      text-align: center;
      color: #8e949b; // $medium-gray
      padding: 30px 12px;
    }
  }

  .rh-glyphs-down-arrow {
    color: #b7b7b7; // $shade-dark
    font-size: 7px;
    line-height: 7px;
    position: absolute;
    right: 10px;
    top: 13px;
  }

  .rh-glyphs-close {
    position: absolute;
    top: 10px !important;
    right: 10px !important;
  }
}

.media-selection-header {
  .page-actions {
    z-index: 1;
    width: auto;
  }
  .menu-link {
    float: right;
  }
  .menu {
    background: $white;
  }
}
