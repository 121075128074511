.all-categories {
  float: left;
  position: relative;
  width: 330px !important;
  input.text {
    border-radius: 0;
    margin: 0;
    padding-right: 40px;
    position: relative;
    z-index: 320;
  }
  .rh-glyphs-close {
    top: 10px;
    right: 12px;
    z-index: 330;
  }
  > div {
    background: #FFF;
    position: absolute;
    top: 36px;
    left: 0;
    width: 100%;
    z-index: 310;
    @include dropdown-dropshadow;
  }
  ul {
    margin: 0;
    max-height: 320px;
    overflow-y: scroll;
    width: 100%;
    @include no-bullet;

    ul {
      overflow-y: visible;
      max-height: inherit;
    }
  }
  li {
    a {
      color: $dark-gray;
      display: block;
      font-size: 16px;
      line-height: 16px;
      padding: 7px 12px 6px 32px;
      &:hover,
      &.active {
        background: #e5f4fb;
        color: $link-color;
      }
    }
    &.none,
    &.add {
      text-align: center;
    }
    &.none {
      color: $medium-gray;
      cursor: default;
      padding: 30px 12px;
      &:hover {
        background: #FFF;
      }
    }
  }
  .category-type {
    color: $medium-gray;
    display: block;
    font-size: 16px;
    line-height: 16px;
  }
  .category-type-hd {
    padding: 7px 12px 6px;
  }
  .btn-wrap {
    border-top: 1px solid $border-color;
    margin: 0;
    padding: 5px;
  }
  .jump-links {
    > li {
      float: left;

      .jump-link {
        padding: 9px 6px 8px;

        &.highlighted {
          color: $brand-blue;
        }
        &:hover {
          background: none;
        }
      }

      &:first-child {
        .jump-link {
          padding-left: 7px;
        }
      }
    }
  }
  .add {
    display: block;
    padding: 5px 12px 4px 7px;
    width: 100%;

    &.btn-disabled {
      color: #c6e6f5;
    }
  }
}
/* create-by-categories
==================================== */
.create-by-categories {
  position: relative;
  .all-categories {
    position: absolute; top: 0; right: 0;

    .text {
      height: 36px; width: 100%;
      padding: 2px 10px;
    }
  }
}
