/* ==========================================================================
   Imports
   ========================================================================== */

@import "base/colors";
@import "base/mixins";
@import "components/buttons";
@import "components/upa_list";
@import "templates/settings";

.delete {
  background: transparent;
  color: $medium-gray;
  position: absolute;
  font-size: 16px;
  line-height: 16px;
  width: auto;
  padding: 0;
  @include default-transition;
  &:focus {
    outline: none;
  }
  &:focus,
  &:hover {
    background: transparent;
    color: $dark-gray;
  }
}

.hide {
  display: none;
}

/* ==========================================================================
   Page Header
   ========================================================================== */

.page-header {
  @include group;

  .admin__hd {
    border-bottom: 1px solid $light-gray;
    font-size: 34px;
    line-height: 34px;
    margin: 0 0 12px; padding-bottom: 18px;
    min-height: 53px;
  }
  .page-views {
    float: left;
    font-size: 16px;
    line-height: 16px;
    margin: 0;
    padding-top: 10px;
    @include group;
    @include no-bullet;
    @include antialiased(600, 500);
    li {
      float: left;
      margin-right: 36px;
    }
    a {
      color: $dark-gray;
      &:hover {
        color: $link-hover;
      }
    }
    .active a {
      color: $link-hover;
    }
  }
  .page-actions {
    @include group;
    @include no-bullet;
    float: right;
    margin: 0;
    li {
      float: left;
      margin-right: 12px;
      &:last-child {
        margin: 0;
      }
      > span {
        display: inline-block;
        font-size: 14px;
        line-height: 14px;
        margin: 11px 0;
      }
    }
  }
}
.page-header__filter {
  position: relative;
  float: left;
  max-width: 400px;
  width: 50%;
}
.page-header__filter-close {
  position: absolute; top: 50%; right: 10px;
  margin-top: -8px;
}
.page-header-controls {
  &--padded {
    padding-left: 18px;
    padding-right: 18px;
  }
}

/* ==========================================================================
   Views
   ========================================================================== */

section.view {
  position: relative;
  @include group;
  padding-bottom: 60px;
}

.partners {
  @include group;
  @include no-bullet;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  .grid-item {
    position: relative;
    float: left;
    margin-right: 4px;
    text-align: center;
    width: 308px;

    > img {
      display: inline-block;
      margin: 25px 0;
      min-height: 50px;
    }
  }
  .grid-item__partner-code {
    border-radius: 50%;
    border: 4px solid $brand-blue;
    color: $brand-blue;
    display: inline-block;
    font-size: 38px;
    font-weight: 500;
    margin: 27px 0 27px;
    padding: 4px;
    height: 84px;
    width: 84px;
    line-height: 70px;
  }
  .grid-item__partner-name {
    position: absolute; top: 100px;
    color: $medium-gray;
    font-size: 16px;
    margin: 10px auto 0;
    width: 100%;
    @include text-ellipsis;
    &--short {
      top: 70px;
    }
  }
}

.grid-item {
  border: 1px solid transparent;
  padding: 0;
  position: relative;
  @include default-transition;
  &:hover,
  &.active {
    border-color: $light-gray;
    .grid-item-actions {
      opacity: 1;
    }
  }
  .grid-item-actions {
    border-top: 1px solid $light-gray;
    margin: 0;
    @include default-transition;
    @include group;
    @include no-bullet;
    opacity: 0;
    li {
      border-top: 0;
      border-right: 1px solid $light-gray;
      float: left;
      width: 100%;
      .partners & {
        width: 33.33%;
      }
      a,
      button,
      label {
        background: var(--color-element-fill-inverse-enabled-weak);
        border-radius: 0;
        color: $dark-gray;
        cursor: pointer;
        display: block;
        font-size: 14px;
        line-height: 14px;
        margin: 0;
        padding: 12px 0;
        text-align: center;
        width: 100%;
        @include default-transition;
        &:hover {
          background:var(--color-element-fill-inverse-enabled-weak);
        }
        &:focus {
          outline: 0;
        }
      }
    }
  }
}

/* ==========================================================================
   Basics
   ========================================================================== */


.form-rh.admin-basics {
  dt,
  dd:not(.list-group__dd) {
    padding-right: 10px;
  }
  dt {
    width: 135px;
  }
  dd {
    width: 420px;
  }
  fieldset {
    width: 100%;
    input.text,
    .select,
    select,
    .admin-basics__btn {
      width: 100%;
    }
    label {
      &::first-letter {
        text-transform: uppercase !important;
      }
    }
    input.text {
      margin: 0;
      &#partner_name {
        float: left;
        margin-right: 10px;
        width: 204px;
      }
      &.partner-code {
        padding: 6px 10px;
        width: 109px;
      }
    }
    &.third-party {
      input.text#partner_name {
        width: 100%;
      }
      figure {
        display: inline-block;
        margin: 0;
        padding-right: 30px;
        position: relative;
        .rh-glyphs-close {
          cursor: pointer;
          top: 0;
          right: 0;
        }
      }
      dd > p {
        font-size: 16px;
        line-height: 16px;
        margin: 0;
        padding: 10px 0;
      }
    }
  }
  .api-keys {
    line-height: 16px;
    padding: 10px 0;
    &::last-child {
      padding-bottom: 20px;
    }
  }
  #api_creds dt, address{
    margin-bottom: 10px; padding-top: 0; padding-bottom: 0;
    line-height: inherit;
  }
  .creds__container {
    background-color: $bg-gray;
    margin-bottom: 20px; padding: 6px;
    width: 545px;
    dt, dd {
      margin-bottom: 4px !important;
    }
    dt {
      width: 132px !important;
    }
    dd {
      width: 370px !important;
    }
    .cb {
      margin-top: -6px;
    }
    code {
      background-color: transparent;
      border: none;
      color: $dark-gray;
      padding-right: 0; padding-left: 0;
    }
  }
  .creds__item {
    font-size: 14px;
  }
  .creds--disabled {
    display: none;
  }
}

.form-rh.admin-basics,
.form-rh.admin-access {
   fieldset {
    .fare-types {
      input.text {
        float: left;
        margin-right: 10px;
        width: 204px;
      }
      ol {
        margin-top: 20px;
        margin-bottom: 0;
        li {
          padding-left: 20px;
        }
      }
    }
  }
}

/* ==========================================================================
   Access
   ========================================================================== */

.admin-access__delete {
  top: 0;
  right: -32px;
}
.card__new-user {
  padding: 20px;
  width: 66%;
  margin-bottom: 20px;
  .form-rh {
    .admin-access__delete {
      top: 9px;
    }
    .admin-access__dt,
    .admin-access__dd {
      margin-bottom: 10px;
    }
  }
}
.form-rh {
  fieldset {
    width: 100%;
  }
  .admin-access__dt {
    margin-right: 10px;
    padding-right: 0;
    width: 8.33333%;
  }
  .admin-access__dd {
    position: relative;
    width: 33.33333%;
    > span {
      color: $dark-gray;
      font-size: 16px;
      line-height: 16px;
      padding: 10px 0;
    }
    input.text {
      margin: 0;
      width: 100%;
    }
  }
  .admin-access__dl.users {
    margin-bottom: 20px;
    .admin-access__dd {
      font-size: 16px;
      line-height: 16px;
      padding: 10px 0;
    }
    .admin-access__dt,
    .admin-access__dd {
      padding-top: 0;
    }
    &:after {
      background: $light-gray;
      content: "";
      width: 427px;
      height: 1px;
    }
  }
  .admin-access__dt,
  .admin-access__dd {
    margin: 0;
  }
  .admin-access__dd:last-child {
    margin-bottom: 10px;
  }
}

.off-viewport {
position: absolute;
top: -800px;
left: 0;
}
/* ==========================================================================
   Partnership Access
   ========================================================================== */
.admin-access-tabs {
  display: block;
  height: 57px;
  @include default-transition();

  &.admin-access-tabs--hidden {
    opacity: 0;
    height: 0;
  }
}
