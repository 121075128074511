button {
  border: none;
}
@mixin btn__action {
  transition: background 200ms ease-out, color 200ms ease-out;
  background-color: $bg-gray;
  color: $dark-gray;
  cursor: pointer;
  display: block;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
  padding: 12px 0;
  text-align: center;

  &:hover {
    background-color: $light-gray;
    color: $dark-gray;
  }
  &:focus {
    outline: 0;
  }
}
