/* ==========================================================================
   Settings
   ========================================================================== */

#settings,
#admin-access {
  .page-header {
    h3 {
      font-size: 20px;
      line-height: 20px;
      float: left;
      margin: 0;
      padding-top: 8px;
    }
    .form-rh {
      input.text {
        float: left;
        width: 168px;
      }
    }
  }
  .view-content {
    min-height: $min-height-content;
    padding-bottom: 23px;
  }
  .view-list-item {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 12px;

    .handle {
      left: 240px;
    }
  }
  .sortable-ghost {
    opacity: 0;
  }
  .airlines {
    @include group;
    @include no-bullet;
    margin: 12px;
    .grid-item {
      border-width: 2px;
      float: left;
      line-height: 0;
      margin-bottom: 14px;
      text-align: center;
      width: 333px;
      > img {
        display: block;
        margin: 20px auto;
        filter: grayscale(100%);
        filter: gray;
        opacity: .5;
        transition: opacity 250ms ease-out;

      }
      .grid-item__partner-code {
        border-radius: 50%;
        border: 4px solid $brand-blue;
        color: $brand-blue;
        display: inline-block;
        font-size: 34px;
        font-weight: 500;
        line-height: 62px;
        margin: 20px 0 32px;
        padding: 4px;
        height: 72px;
        width: 72px;
        opacity: .5;
        transition: opacity 250ms ease-out;
      }
      &.on,
      &:hover {
        > img,
        .grid-item__partner-code,
        .grid-item__partner-name {
          filter: none;
          opacity: 1;
        }
      }
      &.on {
        .access-toggle .access-toggle-text {
          color: $brand-blue;
        }
        .options svg {
          fill: $brand-blue;
        }
        &:hover {
          border-color: rgba($brand-blue, 0.1);
        }
      }
      &:hover {
        border-color: $border-light;
      }
    }
  }
  .partner-item__name {
    position: absolute;  top: 104px;
    font-size: 14px;
    color: $medium-gray;
    text-align: center;
    width: 100%;
  }
  .partner-item--single .access-toggle {
    margin-bottom: -10px !important;
  }
  .access-toggle {
    display: inline-block;
    margin-bottom: 30px;
    @include group;
    .access-toggle-text {
      color: $light-gray;
      cursor: pointer;
      float: left;
      font-size: 16px;
      line-height: 20px;
      margin-left: 10px;
    }
  }
  .options {
    display: inline-block;
    margin: 0 -20px 30px 16px;
    vertical-align: top;

    svg {
      cursor: pointer;
      fill: $medium-gray;

      &:hover {
        fill: $link-hover !important;
      }
    }
  }
  .airlines.config-partner {
    margin: 0 auto;
    width: 350px;

    .grid-item {
      width: 273px;
      &:hover {
        border-color: transparent;
      }
      > img {
        margin: 0 auto;
      }
      .access-toggle {
        margin-top: 10px; margin-bottom: 20px;
      }
    }
  }
  .partners-settings__close {
    position: absolute; top: 12px; right: 12px;
  }
}
.switcher-arrow {
  cursor: pointer;
  float: left;
  margin-top: 53px; padding: 0;

  &:hover {
    color: $link-hover;
  }
  &:first-child {
    padding-right: 0
  }
  &:last-child {
    padding-left: 0
  }
}
.rh-switch {
    border: none;
    margin-bottom: 1rem;
    outline: 0;
    padding: 0;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    float: left
}

.rh-switch label {
    background: #DDDDDD;
    color: transparent;
    cursor: pointer;
    display: block;
    margin-bottom: 1rem;
    position: relative;
    text-indent: 100%;
    width: 4rem;
    height: 2rem;
    transition: left 0.15s ease-out
}

.rh-switch input {
    left: 10px;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 9px
}

.rh-switch input+label {
    margin-left: 0;
    margin-right: 0
}

.rh-switch label:after {
    background: #fff;
    content: "";
    display: block;
    height: 1.5rem;
    left: .25rem;
    position: absolute;
    top: .25rem;
    width: 1.5rem;
    -webkit-transition: left 0.15s ease-out;
    -moz-transition: left 0.15s ease-out;
    -o-transition: translate3d(0, 0, 0);
    transition: left 0.15s ease-out;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.rh-switch input:checked+label {
    background: #1d4e8f
}

.rh-switch input:checked+label:after {
    left: 2.25rem
}

.rh-switch label {
    height: 1.2rem;
    width: 2.4rem
}

.rh-switch label:after {
    height: 0.7rem;
    width: 0.7rem
}

.rh-switch input:checked+label:after {
    left: 1.45rem
}

.rh-switch label {
    color: transparent;
    background: #DDDDDD
}

.rh-switch label:after {
    background: #fff
}

.rh-switch input:checked+label {
    background: #1d4e8f
}

.rh-switch label {
    border-radius: 4px
}

.rh-switch label:after {
    border-radius: 4px
}

.rh-switch label {
    height: 19px;
    width: 38px
}

.rh-switch label:after {
    height: 17px;
    width: 17px;
    top: 1px;
    left: 1px
}

.rh-switch input:checked+label:after {
    left: 20px
}


/* ==========================================================================
   Settings - Partners Config
   ========================================================================== */

.config-access-bar {
  border-top: 1px solid $border-color;
  padding: 25px 15px 15px;
}
.config-access-legend,
.config-access-cb,
.config-access-or {
  display: inline-block;
  margin: 0 20px 0 0;
}
.config-access-cb {
  input {
    margin: 0;
  }
}
.access__codeshares {
  @include group;
  margin: 10px 0 0;
}
.add-category-block {
  position: relative;
  margin-bottom: 0; padding: 0 30px;
}
.config-categories-block {
  float: left;
  width: 60%;
}
.partner-config__select-by-type {
  float: left;
  padding-top: 5px;

  .dot-separator {
    font-size: 26px; font-weight: bold;
    line-height: 1px;
    margin: 2px 4px 0;
  }
}
.partner-config-category-status {
  position: absolute; top: 0; left: 0;
  line-height: 36px;
  text-align: center;
  width: 100%;
  z-index: -1;
}
.partner-config-filter-block {
  padding: 0 15px;
  margin-bottom: 30px;
}
.partner-config-upa-filter {
  position: relative;
  float: left;
  margin-right: 10px;
  width: 392px;
  .text {
    width: 100%;
  }
  .rh-glyphs-close {
    position: absolute; top: 50%; right: 7px;
    margin-top: -7px;
  }
}
.partner-config-upas-status {
  float: right;
  line-height: 18px;
  padding-top: 10px;
  text-align: right;
  width: 40%;

  &.status__limited {
    color: $green;

    > a {
      color: $green;
      text-decoration: underline;
    }
  }
}

/* ==========================================================================
   Settings - PCCs
   Moved to component specific style sheet
   ========================================================================== */

/* ==========================================================================
   Settings - Team
   ========================================================================== */

#profile {
  .form-rh {
    fieldset {
      .avi {
        float: left;
        position: relative;
        height: 150px; width: 150px;
        text-align: center;
        overflow: hidden;
        label {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          &.label__overlay {
            background-color: var(--color-background-primary-enabled-strong);
            padding: var(--space-xs);
            cursor: pointer;
          }
        }
        img {
          max-height: 100%;
        }
        .loader {
          background: #FFF;
          margin-bottom: 40px;
          position: relative;
          width: 150px;
          height: 110px;
        }
      }
      dl {
        float: right;
        width: 66.66667%;
      }
      dd {
        float: none;
        width: 100%;
        &.change-password {
          border-top: 1px solid $light-gray;
          padding-top: 20px;
        }
      }
    }
  }
}

#team {
  .users {
    @include no-bullet;
    @include group;
    > li {
      float: left;
      margin: 0 14px 14px 0;
      height: 186px;
    }
    .grid-item {
      &:hover {
        figcaption {
          .no-opacity & {
            visibility: hidden;
          }
          .opacity & {
            opacity: 0;
          }
        }
      }
    }
    .grid-item-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 20;
      .delete {
        background: #FFF;
        font-size: 16px;
        line-height: 16px;
        padding: 3px;
        bottom: auto;
        top: -151px;
        right: 0;
      }
    }
    figure {
      position: relative;
      figcaption {
        font-size: 16px;
        line-height: 34px;
        padding: 0 10px;
        position: absolute;
        top: 150px;
        left: 0;
        text-align: center;
        width: 100%;
        z-index: 10;
        @include default-transition;
        span {
          display: block;
          overflow: hidden;
          position: relative;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 130px;
          &.badge {
            padding-left: 38px;
            text-align: left;
          }
          i {
            font-size: 32px;
            line-height: 32px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}

/* ==========================================================================
   Settings - Editable Ordered Lists
   ========================================================================== */

.editable-ordered-list {
  .delete-list-item {
    display: none;
  }
  .view-list-item:hover {
    .delete-list-item-enable {
      position: absolute; left: -28px; top: 1px; z-index: 10;
      background: #fff;
      cursor: pointer;
      display: block;
      height: 36px; width: 25px;
      padding: 12px 0 0;
    }
  }

  .view-list-item {
    position: relative;
    &.ui-sortable-helper {
      opacity: 0.5;
      .handle {
        cursor: move;
        i {
          background: $brand-blue;
        }
      }
    }
    &.placeholder {
      border: 2px solid $brand-blue;
      width: 290px;
      height: 36px;
    }
    input.text {
      margin: 0;
    }
  }
  .id {
    color: $medium-gray;
    font-size: 16px;
    line-height: 36px;
    margin-left: 30px;
  }
  .cat-error {
    position: absolute; top: 0; left: 322px;
    background: #fff;
    color: $alert;
    font-size: 16px;
    line-height: 36px;
    padding-right: 100px;
  }
  .tooltip {
    width: 275px !important;
  }
}
.handle:not(.handle-custom) {
  cursor: -moz-grab;
  cursor: -webkit-grab;
  cursor: grab;
  position: static;
  top: 50%;
  left: 290px;
  margin-top: 50px;
  padding: 10px;
  i {
    background: $medium-gray;
    display: block;
    margin-bottom: 2px;
    width: 12px;
    height: 1px;
    @include default-transition;
    &:last-child {
      margin: 0;
    }
  }
}
.form-rh .fare-types__cabin-select {
  width: 200px;
}
