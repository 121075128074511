/* ==========================================================================
   Gallery: Icons / Photos / Videos / Tours
   ========================================================================== */
@import 'typography';
@import 'utilities';

.gallery {
  .view .media-gallery {
    @include group;
    @include no-bullet;
    @include padded-row;
    margin: 0;
    padding-bottom: 13px;
    display: flex;
    flex-wrap: wrap;
  }

  .grid-item-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .grid-item-action {
      border: 0;
      float: left;
      width: 50%;

      .grid-item-action__delete {
        bottom: 140px;
        position: absolute;
        right: 3px;
        display: block;
        width: 30%;
        padding: 0;
        background-color: transparent;
        text-align: right;

        .icon {
          height: 15px;
          width: 15px;
        }
      }
    }

    .gallery-preview {
      border-right: 1px solid $border-color;
    }
  }

  .airport-search-block {
    .typeahead__suggestions {
      margin-top: 36px;
    }
  }
}

.gallery-preview-btn {
  cursor: pointer;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.list-view {
  .grid-item {
    float: none;
    margin: 0 0 10px;
    padding-left: 8px;

    &:hover {
      border-color: transparent;
    }

    .grid-item-img {
      margin: 8px auto;
    }
  }

  .grid-item-actions {
    border: 0;
    left: auto;
    right: 0;
    width: 45%;
    height: 100%;

    .grid-item-action {

      .delete,
      .gallery-select-btn,
      .grid-item-action__delete {
        bottom: auto;
        font-size: 16px;
        line-height: 16px;
        margin-top: -8px;
        top: 50%;
        right: 0px;
      }

      &.gallery-preview {
        border-right: 0;
        margin-top: -20px;
        position: absolute;
        top: 50%;
        right: 102px;
        width: 70px;

        .gallery-preview__btn {
          border: 1px solid $light-gray;
        }
      }

      .gallery-select-btn {
        right: inherit;
        left: 0;
      }
    }

    .grid-item-action.update {
      .replace {
        border: 1px solid $light-gray;
      }

      .gallery-meta-apply-all {
        border: none;
        background-color: inherit;
        color: $link-color;
        position: inherit;
        width: 100px;
      }

      .replace {
        margin-top: -20px;
        position: absolute;
        top: 50%;
        right: 32px;
        width: 70px;
      }

      .cancel {
        bottom: auto;
        margin-top: -7px;
        top: 50%;
        right: 20px;
      }

      textarea {
        width: 100%;
        height: 100%;
      }
    }
  }

  figure {
    float: left;
    margin-right: 20px;
    text-align: center;
  }

  &:not(.gallery-meta-view) {
    figure {
      overflow: hidden;
      padding-bottom: 10px;
    }
  }

  .meta-icon,
  .meta-photo,
  .meta-video,
  .meta-tours {
    float: left;
    margin-top: 8px;
    text-align: left;
  }

  .meta-photo,
  .meta-video,
  .meta-tours {
    width: 400px;
  }

  .filename,
  .title {
    font-size: 16px;
    line-height: 16px;
    margin: 0 0 8px;
  }

  .added {
    color: $medium-gray;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }

  .meta-photo-details-category {
    text-transform: capitalize;
  }

  .meta-added-by-link {
    color: $medium-gray;
    text-decoration: underline;
  }

  .meta-details-category {
    &:after {
      content: ', ';
    }
  }

  .meta-details-cabins:not(:first-child) {
    &:before {
      content: ', ';
    }
  }
}

/* ==========================================================================
   Gallery: Photos / Videos / Tours
   ========================================================================== */
.grid-item__photos,
.grid-item__videos,
.grid-item__tours {
  float: left;
  min-height: 163px;
  min-width: 130px;

  figure {
    margin: 0 4px;
  }

  .grid-item-img {
    display: block;
    margin: 22px auto 0;
    height: 100px;

    &.loading {
      height: auto;
      padding: 34px;
    }
  }

  .grid-item-actions {
    .grid-item-action {
      .delete {
        bottom: 140px;
      }

      .gallery-select-btn {
        position: absolute;
        left: 3px;
        bottom: 139px;
        background: transparent;
        color: $medium-gray;
        padding: 0;
        padding-right: 158px;
        width: 178px;

        &:hover {
          background: transparent;
          color: $dark-gray;
        }

        .icon {
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  &.ds-hover {
    border-color: transparent;

    .grid-item-img {
      border-color: #0096d6;
      opacity: .5;
    }

    .grid-item-actions {
      visibility: hidden;
    }
  }

}

.list-view {

  .grid-item__photos,
  .grid-item__videos,
  .grid-item__tours {
    min-height: 168px;

    .grid-item-img {
      height: 150px;

      &.loading {
        max-height: 100px;
      }
    }
  }
}

#photos,
#videos {
  .list-view {
    figure {
      margin: 0 var(--space-s) 0 0;
      width: 300px;
    }

    img {
      height: 150px;
    }
  }
}

.meta-gallery__grid-item-actions {
  width: 142px !important;
}

/* ==========================================================================
   Gallery: Icons
   ========================================================================== */
#icons.gallery {
  .grid-item-actions {
    .grid-item-action {
      float: none;
      width: 100%;
    }
  }
}

.grid-item__icons {
  text-align: center;
  min-height: 145px;
  width: 168px;

  .grid-item-img {
    position: relative;

    &.loading {
      top: 31px;
    }

    &.icon-lg {
      top: 10px;
    }
  }
}

.list-view {
  .grid-item__icons {
    width: 100%;
    min-height: 102px;

    img.icon-lg {
      top: 0;
    }

    .loader {
      width: 84px;
      margin-left: 8px;
    }

    figure {
      min-width: 84px;
    }
  }
}

/* ==========================================================================
   Gallery: Icons / Photos
   ========================================================================== */

#icons,
#photos {
  .grid-item {
    .loader {
      position: relative;
    }
  }

  .rh-glyphs-grid-view,
  .rh-glyphs-list-view {
    position: relative;
  }

  .list-view {
    .grid-item-actions {
      left: auto;
      right: 0;
      width: 202px;
    }
  }
}

.photos__warning {
  background-color: $caution-blue;
  height: 18px;
  font-size: 11px;
  line-height: 13px;
  text-wrap: nowrap;
}

.photos__figure {
  margin: 0 4px;
  text-align: center;
  min-width: 122px;
  position: relative;
  .photo-warning {
    left: 3px;
    right: 3px;
    top: 0;
    position: absolute;
  }
}
.photo-warning-meta {
  position: absolute;
  width: 150px;
  top: 0;
  left: calc(50% - 75px)
}

.photo-processing__content {
  .list-view & {
    float: left;
    margin-right: 20px;
    width: 300px;
  }

  &.photo-processing__content--processing {
    text-align: center;
  }
}

.photo-processing__icon {
  display: block;
  fill: $dark-gray;
  margin: 20px auto 28px;
}

.photo-processing__text {
  font-size: 14px;
  max-width: 200px;
  margin: -25px auto 0;
  padding: 0 12px 12px;

  .gallery-meta-view & {
    display: block;
  }
}

.grid-item__icons .loader {
  height: 104px;
}

.grid-item__photos {
  .loader {
    margin: 22px 4px 0;
    height: 100px;
    width: 100px;
  }
}

.list-view {
  .grid-item__photos {
    .loader {
      margin: 0;
      height: 166px;
      width: 300px;
    }
  }
}

.grid-item__photos {
  .grid-item-img {
    border: 3px solid transparent;
    @include default-transition;
  }

  &.gallery_select__selected {
    .grid-item-img {
      border-color: $brand-blue;
      opacity: .5;
    }

    .grid-item-action {
      .gallery-select-btn {
        color: $brand-blue;
      }
    }
  }
}

.gallery-meta-apply-all {
  position: absolute;
  top: 50%;
  right: 56px;
  margin-top: -20px;
  padding: 8px 12px;
}

/* ==========================================================================
   Gallery: Videos
   ========================================================================== */
.page-action__tours,
.page-action__videos {
  min-width: 214px;
}

.embed-code-form {
  display: flex;
  flex-flow: row nowrap;
}

.embed-text-container {
  min-height: 26px;
  min-width: 180px;
  position: relative;

  a {
    @include default-transition;
    top: 6px;
    right: 130px;
  }
}

.gallery-code-input-block {
  position: absolute;
  top: 0;
  right: 3px;
  background: #fff;
  height: 36px;
  width: 180px;
  overflow: hidden;
  padding: 0 3px;
  z-index: calc(var(--z-index-banner) + 1);
  @include default-transition;

  &.active {
    height: 180px;
    width: 230px;

    .gallery-embed-code-input {
      height: 130px;
      overflow: auto;
    }

    &+a {
      right: 240px;
    }
  }

  .gallery-share-code-input {
    height: 36px;
    width: 100%;
    min-height: 36px;
    overflow: hidden;
  }

  .gallery-embed-code-input {
    height: 36px;
    width: 100%;
    min-height: 36px;
    overflow: hidden;
    resize: none;
  }

  .gallery-code-input-cancel {
    font-size: 14px;
    line-height: 1;
    padding: var(--space-xxs) 0 var(--space-xs);
    cursor: pointer;
  }
}

.video_embed_replace_input {
  height: 100%;
  width: 100%;
}

.page-actions .page-action__videos,
.page-actions .page-action__tours {

  >span,
  form {
    float: left;
  }

  >span {
    margin-right: 12px;
  }
}

.grid-item__videos,
.grid-item__tours {
  .grid-item-actions {
    .grid-item-action.update {
      .text-container {
        position: absolute;
        bottom: 39px;
        left: 4px;
        width: 178px;
        height: 100px;
        z-index: 20;
      }

      .update-inputs {
        border-radius: 0;
        line-height: 1;
        padding: 6px 8px;
        resize: none;
        width: 100%;
        transition: box-shadow 200ms ease-out;
      }

      textarea.update-inputs {
        height: 100px;
      }

      button[type=submit],
      .gallery-submit-btn {
        background: $brand-blue;
        color: #FFF;

        &:hover {
          background: $link-color;
        }
      }

      .cancel {
        background: transparent;
        color: $link-color;
        padding: 0;
        position: absolute;
        bottom: 143px;
        right: 5px;
        width: auto;

        &:hover {
          background: transparent;
          color: $link-hover;
        }
      }
    }
  }

  .list-view {

    .grid-item__videos,
    .grid-item__tours .grid-item-actions {
      .grid-item-action.update {
        .text-container {
          top: 32px;
        }
      }
    }
  }
}

#tours {
  .gallery-code-input-block {
    &.active {
      height: 210px;
    }

    .gallery-embed-code-input {
      margin-top: 5px;
    }
  }

  .grid-item {
    .hide-tour-thumb {
      opacity: 0;
    }

    .grid-item-actions {
      .grid-item-action.update {
        .text-container {
          textarea {
            margin: 4px 0 0;
            height: 58px;
          }
        }
      }
    }
  }

  .list-view .grid-item .hide-tour-thumb {
    opacity: 1;
  }

  .grid-item-loader {
    min-width: 178px;

    &:hover {
      border: none;
    }

    .rh-loader-container {
      margin-top: 61px;
    }
  }

  .tour-replace-loader {
    position: absolute;
    top: 61px;
    left: 50%;
    margin-left: -13px;
  }
}

.media-gallery__tours {
  display: table;
  width: 100%;

  div {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  @media (max-height: 700px) {
    height: 500px;

    div {
      height: 500px;
    }

    iframe {
      max-height: 100%;
    }
  }
}

/* ------
Meta View
--------- */
$gallery-meta-input-width: 350px;

.meta-view-hd {
  @include headline-s();
}

.gallery-meta-view {
  margin-left: 0;
  margin-bottom: 0;

  .grid-item {
    border: none;
    &:not(:first-child){
      border-top: 1px solid $border-color;
    }
    @include no-bullet;
    overflow: visible;
    padding: var(--space-s) 0;
  }

  .meta-photo {
    position: relative;
    background-color: #fff;
    padding-left: var(--space-s);
    width: $gallery-meta-input-width + 100 !important;
  }
}

.meta-view__figure {
  position: relative;
  margin: 0 !important;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 75px;
    background-color: rgba(255, 255, 255, .75);
    height: 100%;
    width: 75px;
    z-index: 3;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 225px;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, .75);
    height: 100%;
    width: 75px;
    z-index: 3;
  }
}

.meta-view__photo-dimensions {
  position: absolute;
  bottom: -12px;
  font-size: 11px;
  text-align: center;
  width: 100%;
}

.meta-photo .gallery-meta-name {
  margin-bottom: var(--space-xs);

  .text {
    width: $gallery-meta-input-width;
  }
}

.gallery-meta-row {
  @include group;
  width: 100%;

  .multicheck-dropdown {
    width: $gallery-meta-input-width - 100;
    margin: 0;
  }
}

.gallery-meta-category {
  position: relative;
  margin: 10px 14px 0 0;

  .selected-categories {
    ul {
      list-style-type: none;
    }
    li.category {
      float: left;
      margin-right: 10px;

      span a {
        top: 12px;
      }
    }
  }
}

.gallery-meta__category {
  margin-bottom: 10px;
  display: flex;
}

.gallery-meta__category-text {
  margin-right: 10px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.gallery-meta__secondary-category {
  display: inline-block;
  vertical-align: middle;
}

.gallery-meta__search,
.submenu {
  .fs-form {
    input.fs-input {
      height: 36px;
      line-height: 36px;
    }

    .fs-input-location {
      position: relative;
      flex-grow: inherit;
      width: 100%;
    }
  }

  .rh-select-block,
  .airport-search-block {
    height: 36px;
    margin: 0;
  }

  .ui-select-container {
    .form-control {
      height: 36px;
    }

    .btn {
      padding: 8px 12px;
    }
  }
}

.gallery-meta__search {
  .airport-search-block {
    width: $gallery-meta-input-width - 100;
  }
}

.gallery-meta-cabin {
  clear: both;
  margin: 10px 0;

  .cbs {
    padding: 5px 0;
    column-count: 2;
    column-gap: 20px;
  }

  .cb {
    break-inside: avoid;
    float: none;
    padding: 6px 0;

    input[type="checkbox"] {
      top: 6px;
      margin: 0;
    }

    label {
      &:before {
        top: 5px;
      }
    }
  }
}

#photos .gallery-meta-view.list-view .grid-item-actions .grid-item-action {
  .gallery-meta-apply-all {
    top: 0;
    margin-top: 8px;
    padding: 10px 0;
  }

  .delete {
    top: 0;
    margin-top: 18px;
  }
}
