.media {
  display: flex;
  align-items: flex-start;
}
.media__figure {
  margin-right: 12px;

  &.media__figure--centered {
    align-self: center;
  }
}
.media__body {
  flex-grow: 1;
}

/* ==========================================================================
   Mixins
   ========================================================================== */

@mixin group {
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

#media_show {

  .btn {
    border-radius: 4px;
    display: block;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    text-align: center;
    text-decoration: none;
    &.reverse,
    #media & {
      color: #FFF;
    }
  }

  [class^="icon-"], [class*=" icon-"] {
    font-family: 'media-window';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .transition-all {
    transition: all 200ms ease-out;
  }

  .transition-bg {
    transition: background 200ms ease-out;
  }

  a {
    text-decoration: none;
  }

  .btn {
    border: 1px solid $brand-blue;
    color: $brand-blue;

    &:hover {
      border-color: darken($brand-blue, 4%);
      color: darken($brand-blue, 4%);
    }
  }

  padding: 10px;
  width: 640px;
  height: 695px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .content__wrapper {
    height: 470px; width: 620px;
    margin-bottom: 15px;
    overflow: hidden;
  }
  #content {
    background: #000;
    display: table;
    list-style: none;
    padding: 0;
    width: 620px;
    height: 470px;
    span {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }
    img {
      max-width: 620px;
      max-height: 470px;
    }
    iframe {
      display: block;
    }
  }
  #description {
    margin-bottom: 26px;
    dl {
      color: #1b1b1b;
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      dt {
        font-weight: bold;
      }
      dd {
        height: 40px;
        margin: 0;
      }
      a {
        text-decoration: none;
      }
    }
    .small-9 {
      padding: 0 10px 0 0;
    }
    .small-3 {
      padding: 0;
    }
    .btn {
      font-size: 18px;
      line-height: 18px;
      padding: 20px 0;
    }
  }
  #carousel {
    position: relative;
    #container {
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      width: 540px;
      height: 100px;
      z-index: 0;
      &:before,
      &:after {
        border: solid transparent;
        content: " ";
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 0;
      }
      &:before {
        border-top-color: $brand-blue;
        border-width: 8px;
        margin-left: -8px;
        z-index: 10;
      }
      &:after {
        border-width: 6px;
        border-top-color: #FFF;
        margin-left: -6px;
        z-index: 20;
      }
    }
    ul {
      @include group;
      margin: 0 0 0 210px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      transition: left 300ms;
    }
    li {
      cursor: pointer;
      float: left;
      margin-left: 10px;
      width: 100px;
      img:hover,
      &.active img {
        border: 2px solid $brand-blue;
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      }
      img {
        opacity: .5;
        width: 100px;
        height: 100px;
        transition: opacity 150ms ease-out;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      }
    }
    .arrow {
      color: $brand-blue;
      font-size: 24px;
      font-weight: bold;
      position: absolute;
      top: 0;
      width: 40px;
      height: 100px;
      transition: color 200ms ease-out;
      &[data-direction="prev"] {
        left: 0;
      }
      &[data-direction="next"] {
        right: 0;
      }
      i {
        position: absolute;
        top: 50%;
        left: 8px;
        margin-top: -12px;
      }
      &:hover {
        color: darken($brand-blue, 4%);
      }
    }
  }
}
