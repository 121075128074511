$brands-name-width: 336px;
$brands-min-width: 732px;

.brands__list {
  margin-bottom: 0;
  &:last-child {
    margin-bottom: 20px;
  }
}
.brands__header {
  @include group;
  display: flex;
  padding-left: $brands-name-width + 22px;
  margin-bottom: 4px;
  min-width: $brands-min-width;
  .brands__cabin {
    border-bottom: 2px solid;
    font-size: 16px;
    font-weight: 600;
  }
  .icon-Check {
    height: 16px; width: 16px;
  }
}
.brands__item {
  position: relative;
  clear: both;
  height: 36px;
  line-height: 26px;
  min-width: $brands-min-width;
  padding: 5px 0;
  .brands__list--editable & {
    height: 46px;
    line-height: 36px;
  }
}
.brands__name {
  float: left;
  width: $brands-name-width;
}

.brands__name-input,
.brands__code-input {
  margin-right: 12px;

  .form-rh & {
    height: 36px; width: 156px;
  }
}
.brands__cabin {
  margin-right: 8px;
  text-align: center;
  white-space: nowrap;
  width: 84px;
  .cb {
    float: none;
    margin: 0 auto;
    padding: 5px 0;
    width: 16px;

    label {
      &:before {
        top: 6px;
      }
    }
  }

  .rh-cb__container.no-label {
    float: none;
  }
}
.brands__remove {
  position: absolute; left: -28px; top: 3px; z-index: 10;
  background-color: #fff;
  cursor: pointer;
  height: 36px; width: 25px;
  outline: none;
  padding: 5px 0;
  @include rh-hover;
  .icon {
    height: 16px; width: 16px;
    fill: $dark-gray;
  }

  .brands__item & {
    display: none;
  }
  .brands__item:hover & {
    display: block;
  }
}
.brands__list .brands__handle {
  position: inherit; top: inherit; left: inherit;
  background-color: #fff;
  float: left;
  margin: 0;
  padding: 14px 8px;
  width: 30px;
}
