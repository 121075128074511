/* ==========================================================================
   UPAs: Inspect View
   ========================================================================== */
.inspect-actions {
  display: flex;
  align-items: center;
  gap: var(--space-xs);
}

.inspecting-active {
  position: absolute !important;
  z-index: -1 !important;
  opacity: 0;
  height: 70vh;
  overflow-y: scroll;
}

.inspect-view {
  background: var(--color-element-fill-inverse-enabled-weak);
  padding-top: 9px;
  min-height: 420px;

  &.ng-hide-add,
  &.ng-hide-remove {
    transition: min-height 0.3s ease-out;
  }

  &.ng-hide {
    min-height: 0;
  }
}

.upas.inspect__container {
  display: flex;
  flex-flow: row nowrap;
}

.inspect-upa {
  flex-grow: 1;
  padding: 14px 0 0 30px !important;
}

.inspect-upa__avatar {
  text-align: right;
  padding-top: 2px;
  padding-right: 10px;
  float: left;

  img {
    max-width: 30px;
  }
}

.inspect-upa-meta {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: var(--space-s);

  .column {
    padding-left: 0;
    min-width: 160px;
    &:has(.inspect-upa__avatar) {
      min-width: var(--space-xl);
    }
  }
}

.inspect-upa__term {
  color: var(--color-text-primary-enabled-weak);
  @include paragraph-s(0, var(--space-xxs), 400);
  margin: 0 0 var(--space-xxs);
}

.inspect-upa__definition {
  @include paragraph-m(0, var(--space-xxxs), 400);

  &.upa-meta-date {
    white-space: nowrap;
  }
}

.inspect-targeting-dd {
  margin-bottom: var(--space-s);
}

.inspect-upa-list-link {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: var(--color-text-primary-enabled-weak);
  }
}

.rule-details {
  @include no-bullet;
  margin: 3px 0 10px;
  display: none;
  max-width: 387px
}

.rule__active .rule-details {
  display: block;
}

.rule-detail {
  color: var(--color-text-primary-enabled-weak);
  line-height: 1.2;
  padding: 2px 0 2px 20px;

  .od-both-ways {
    &:after {
      top: 12px;
    }
    position: relative;

    .od-list__rule-icon {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }
}

.od-both-ways__inspect {
  padding-right: 20px;

  &:after {
    top: 3px;
  }
}

.inspect-upa-empty {
  color: var(--color-text-primary-enabled-weak);
  line-height: 36px;
  margin: 50px auto;
  text-align: center;
  width: 95%;
}

.inspect-upa__tageting {
  border-top: 1px solid var(--color-border);
  margin: 14px 0;
  padding: 20px 0 0;
}
