#terms {
  .hd__back-link {
    float: right;
    font-size: 14px;
    line-height: 30PX;
  }
  .terms-row {
    max-width: 62.5rem;
    margin: 0 auto; padding: 60px 0 30px;
  }
  h1 {
    border-bottom: 1px solid $light-gray;
    font-size: 34px;
    line-height: 30px;
    margin: 0 0 30px;
    padding-bottom: 15px;
  }
  h3 {
    font-weight: bold;
    margin: 0;
  }
  h3,
  p {
    font-size: 18px;
    line-height: 24px;
  }
  .intro {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 40px;
  }
  p {
    margin-bottom: 30px;
  }
  .term-options {
    background: $bg-gray;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 1000;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .4);
    div {
      display: table;
      margin: 0 auto;
      width: 1000px;
      height: 100%;
    }
    p {
      color: $medium-gray;
      font-size: 16px;
      line-height: 16px;
      display: table-cell;
      vertical-align: middle;
      &.read {
        color: $green;
      }
    }
    ul {
      list-style-type: none;
      display: table-cell;
      text-align: right;
      vertical-align: middle;
      li {
        display: inline-block;
        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }
}
