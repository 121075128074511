.new-cb {
  margin-bottom: 10px;
  line-height: 16px;
  font-size: 16px;
  position: relative;

  label {
    cursor: pointer;
    display: inline-block;
    padding-left: 25px;

    &:before {
      background: $bg-gray;
      background-size: 14px 14px;
      border: 1px solid $border-color;
      content: "";
      padding: 0 1px;
      position: absolute;
      top: 4px;
      left: 0;
      width: 16px;
      height: 16px;
      z-index: 10;
      transition: all 100ms linear;
    }
  }

  &.checked label:before {
    background-image: url('/assets/svg/check.svg');
  }

  &.checked.disabled label:before {
    background-image: url('/assets/svg/check-disabled.svg');
  }

  &.disabled {
    color: $medium-gray;
  }
}
