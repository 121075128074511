.program {
  ul {
    @include no-bullet;
  }
  &.group {
    display: flex;
  }
}
.program__categories-list {
  padding: 0 2px 12px 0;
  width: 182px;
}
.program__category {
  color: $dark-gray;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  @include text-ellipsis;
  width: 100%;
}
.program__fare-brands--scroll {
  overflow-x: auto;
  width: calc(100% - 182px);
}
.program__fare-brands-list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-bottom: 12px;
  width: 100%;
}
.program__fare-brand {
  padding: 0 2px;
  text-align: left;
  width: 162px;
}
.program__program-title,
.program__fare-brand-title {
  border-bottom: 2px solid $border-color;
  font-size: 16px; font-weight: normal;
  height: 35px;
  line-height: 1;
  margin: 0 0 5px; padding: 12px 0 0;
  @include text-ellipsis;
  text-transform: uppercase;
  width: 100%;
}
.program__uta {
  font-size: 14px; font-weight: 600;
  height: 24px;
  line-height: 24px;
  @include text-ellipsis;
  width: 100%;
  a, span {
    color: $dark-gray;

    &.segment-uta__na {
      color: $medium-gray;
      font-weight: normal;
    }
  }
  .segment-uta__link {
    color: $link-color;
    &:not(:last-child):after {
      content: ', '
    }
  }
  .segment-uta__span {
    &:not(:last-child):after {
      content: ', '
    }
  }
}
.program__empty {
  color: $medium-gray;
  font-size: 18px;
  font-weight: 300;
  margin: 0 auto; padding: 50px 0;
  text-align: center;
}
