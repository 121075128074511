/* ==========================================================================
   Variables
   ========================================================================== */

$brand-blue: #204f8c;
$link-color: $brand-blue;
$link-hover: #0066cc;
$link-visited: #0066cc;
$light-blue: #e5f4fb;
$caution-blue: #95CAF5;
$dark-gray: hsla(215,57.1%,16.5%,1);
$medium-gray: hsla(215,17.8%,39.6%,1);
$light-gray: #d8d8d8;
$bg-gray: #f8f8f9;
$bg-blue: #e3f5ff;
$disabled: #8fa7c5;
$purple: #7a3dbf;
$green: #57af10;
$orange: #f96041;
$red: #cd1e20;
$border-color: $light-gray;
$border-light: #eee;
$input-border-color: #d3d3d3;
$text-color: $dark-gray;
$shade: #F3F4F4;
$shade-dark: #b7b7b7;
$color-positive: $brand-blue;
$color-neutral: $dark-gray;
$color-negative: $red;
$alert: #fa6501;
$notice: $brand-blue;
$success: $green;
$darker-gray: #333;
$white: #fff;
