.layout-container {
  display: flex;
  flex-flow: row;
  height: 100vh;
}
.rh-flex-layout {
  display: flex;
  flex-flow: column;
  flex-grow: 2;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: visible;
}
.flex-main {
  flex: 2;
}
.context-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: var(--app-header-height);
  padding: 0 var(--space-l);
  margin-bottom: var(--space-s);
  background-color: var(--color-background-primary-enabled-weak);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}
.rh-main-panel--padded {
  padding: var(--space-s) var(--space-l);
}
.rh-main-panel__content--min-height {
  min-height: $min-height-content;
}
