.upa-list {
  @include no-bullet;
}
.upa-list-row {
  border-top: 1px solid $border-color;
  margin: 0 !important; padding: 10px 20px 10px 15px;
  @include default-transition;

  &:last-child {
    border-bottom: 1px solid $border-color
  }

  &.selected {
    position: relative;
    &:before {
      content: '';
      position: absolute; top: 0; left: 0;
      background: $light-gray;
      display: block;
      height: 100%; width: 6px;
    }
  }
  &.upas-enabled {
    &:before {
      background: $green;
    }
  }
}
.upa-list-component {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  align-content: space-between;
  .flex-column {
    align-self:center;
  }
}

.upa-list-selection {
  > .cb {
    margin-right: 0;
    min-height: 1px;
    padding: 0;
    line-height: inherit;

    &.upa-list-cb {
      float: none;
    }

    label {
      &:before {
        top: 12px;
      }
    }
  }
  .upa-list-id {
    color: $medium-gray;
    font-size: 12px;
    line-height: 42px;
  }
}
.upa-list-icon {
  height: 42px;
  width: 84px;
  text-align: center;

  img {
    max-width: 42px;
  }
}
.upa-list__category-name {
  @include text-ellipsis;
  @include antialiased(600, 500);
  line-height: 42px;
}
.upa-list__sub-label {
  color: $medium-gray;
  font-size: 12px;
  line-height: 42px;
  margin-left: 10px;
}
.upa-list__text {
  padding-right: 10px;
}
.upa-list__headline {
  font-size: 16px;
  font-weight: 600;
}
.upa-list-description,
.upa-list__description {
  font-size: 14px;
  line-height: 21px;
}
.upa-list__media {
  overflow: hidden;
}
.media-thumbs {
  @include no-bullet;
  overflow: hidden;
  height: 100px; width: 100%;
}
.media-thumbs__item {
  float: left;
  height: 100px; width: 100px;
}
