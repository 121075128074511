/* ==========================================================================
   Page Header
   ========================================================================== */
@import 'typography';
@import 'utilities';

.page-header {
  @include group;
  &.page-header--padded {
    padding: 20px 14px;
  }
  &.page-header--sticky {
    position: sticky; top: 0px;
    z-index: var(--z-index-over-menu);
  }
  .upas-summary__content & {
    background-color: var(--color-element-fill-inverse-enabled-weak);
    padding-top: var(--space-s);
    height: calc(50px + var(--space-s)) !important; // normal height plus padding
  }
  &.page-header--pullup {
    margin-top: -60px;
    .page-header-controls {
      background: transparent;
    }
  }
  &.page-header--full-width {
    left: 0 !important;
  }
  &.page-header--observers {
    margin: 20px 0;
    h1 {
      padding-bottom: 0;
    }
  }
  > h1 {
    font-size: 34px;
    line-height: 34px;
    margin: 0;
    padding-bottom: 18px;
  }
  .ua-summary__hd {
    @include paragraph-xl(0, 0, 700);
    line-height: 34px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: var(--space-xs);

    &:first-letter {
      text-transform:capitalize;
    }
    .language-picker__current {
      line-height: 24px;
    }
  }
  .page-header-controls {
    background-color: var(--color-element-fill-inverse-enabled-weak);
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--space-xs);

    &--flex-end {
      justify-content: flex-end;
    }
  }
}
.page-header__filter {
  position: relative;
  float: left;
  max-width: 400px;
  width: 50%;
  .text {
    width: 100%;
  }
}
.page-header__filter--padded {
  margin: 0 12px 12px;
}
.page-header__filter-close {
  position: absolute; top: 50%; right: 10px;
  margin-top: -8px;
}
.page-header__flex {
  display: flex;
  flex-flow: row nowrap;
}
