/* Styles for "Hub Flight Search" library */
.fs-lib {
  display: block;
  margin: 0 auto;

  >footer {
    height: var(--space-xl);
  }

  &__header {
    display: flex;
    margin-bottom: var(--space-s);
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 960px) {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: var(--space-m);
    }
  }

  &__title {
    color: var(--color-button-text-secondary-enabled-medium);
    font-family: Inter;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    padding-bottom: var(--space-s);
    text-align: center;

    @media screen and (min-width: 992px) {
      font-size: 48px;
      line-height: 52px;
      padding-bottom: 0;
      text-align: left;
    }
  }

  &__curl-btn {
    margin-top: 5px;
  }

  &__search {
    display: block;
    margin-bottom: var(--space-xl);
  }

  &__search-tabs {
    display: block;

    .rh-tab-bar {
      padding: 0 !important;
      background-color: #fefefe !important;
    }

    .rh-tab-bar__list {
      flex-direction: column !important;

      @media screen and (min-width: 401px) {
        flex-direction: row !important;
      }
    }

    .rh-tab-bar__item {
      flex: 1;

      .rh-tab__item {
        width: 100%;
        @include paragraph-m();
        font-weight: 700;
        white-space: nowrap;
        color: var(--color-button-text-primary-disabled-medium);

        &--active {
          color: var(--color-button-border-primary-enabled-medium);
        }
      }
    }
  }

  &__flight-search,
  &__find-a-flight {
    background-color: var(--color-background-primary-enabled-weak);
    display: block;
    padding: var(--space-s);

    .flight-form__container {
      display: flex;
      flex-wrap: wrap;

      > * {
        width: 260px;
        margin: 8px;
      }
      > .advanced-filters__label {
        width: 100%;
        text-align: center;
        margin: 16px auto; 
        padding-right: 24px; 
        font-weight: 500; 
        color: var(--color-button-text-primary-enabled-medium); 
        position: relative;
        span {
          margin: auto;
          position: relative;
          padding-right: 24px;
          padding-left: 24px;
          .icon-chevron-up,
          .icon-chevron-down {
            position: absolute; 
            right: -4px;
          }
        }
      }
    }

  }

  &__search-actions {
    padding: var(--space-s);
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  &__flight-search,
  &__find-a-flight {

    .row:not(:last-of-type)>[class*="col-"] {
      margin-bottom: var(--space-m);

      @media screen and (min-width: 992px) {
        margin-bottom: var(--space-l);
      }
    }

    .row>[class*="col-"] {
      padding-right: var(--space-xl);

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &__flight-search {
    rh-ui-checkbox .rh-cb__label {
      width: auto;
    }
  }

  &__find-a-flight {
    .agency-code__value {
      padding-top: 36px;
    }
  }

  &__typehead--horizontal {
    .button__text {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: flex-end;

      &-primary {
        flex: 0 0 50%;
        width: 50%;
        white-space: nowrap;
        text-align: left;
      }

      &-description {
        flex: 0 0 50%;
        text-align: right;
      }
    }
  }

  &__agency-code {
    margin: 0 10px;
    padding: var(--space-s);
    border: 2px solid var(--color-background-primary-enabled-strong) !important;
    border-radius: var(--border-radius-m);
    @include paragraph-s();

    flex: 1 0 0%;

    @media screen and (min-width: 1200px) {
      flex: 0 0 auto;
    }

    &-title {
      @include paragraph-l();
      font-size: 20px;
      font-weight: 500;
      margin-bottom: var(--space-s);
    }

    &-copy {
      margin-bottom: var(--space-s);
    }

    .row>[class*="col-"]:not(:last-of-type) {
      margin-bottom: var(--space-m);

      @media screen and (min-width: 992px) {
        margin-bottom: 0;
      }
    }
  }

  &__checkboxes--horizontal {
    .field__wrapper {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
    }

    .field__help--error {
      width: 100%;
      margin-top: -1.5rem;
    }
  }

  &__results {
    display: block;
  }

  &__results-tabs {
    display: block;
    margin-bottom: var(--space-s);

    .rh-tab-bar {
      padding: 0 !important;
      background-color: #fefefe !important;
    }

    .rh-tab-bar__list {
      flex-direction: column !important;

      @media screen and (min-width: 401px) {
        flex-direction: row !important;
      }
    }

    .rh-tab-bar__item {
      flex: 1;

      .rh-tab__item {
        width: 100%;
        @include paragraph-m();
        font-weight: 700;
        color: var(--color-button-text-primary-disabled-medium);

        &--active {
          color: var(--color-button-border-primary-enabled-medium);
        }

        &--selected-flight:not(.rh-tab__item--active) {
          color: var(--color-text-primary-enabled-medium);
        }
      }
      button:disabled {
        color: var(--color-button-text-primary-disabled-medium); 
      }
    }
  }

  &__search-progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow-y: auto;
    margin-top: calc(var(--space-l) * -1);

    height: 200px;

    @media screen and (min-width: 401px) {
      height: 270px;
    }

    @media screen and (min-width: 768px) {
      height: 320px;
    }

    &-title {
      @include headline-s();
      font-weight: 600;
      margin-bottom: var(--space-s);
    }

    &-copy {
      @include paragraph-m();
      margin-bottom: var(--space-s);
    }

    &-loader {
      display: block;
      width: 60px;
    }
  }

  &__no-results {
    @include headline-s();
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: calc(var(--space-l) * -1);
    padding: 0 var(--space-m);
    height: 200px;

    @media screen and (min-width: 401px) {
      height: 320px;
    }

    @media screen and (min-width: 768px) {
      height: 320px;
    }
  }

  &__result {
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin-bottom: var(--space-l);
    outline: 1px solid #ccc;
    background-color: #f2f3f5 !important; //Needs to override hub-ui styles being imported on top of this for observers module

    &--selected,
    &--selected:hover {
      outline: 3px solid var(--color-button-fill-secondary-enabled-medium) !important;
    }

    &--loading {
        opacity: 0.6;
        -webkit-animation: 3s ease 0s normal forwards 1 fadein;
        animation: 3s ease 0s normal forwards 1 fadein;
        animation-iteration-count: infinite
    }

    @keyframes fadein{
        10% { opacity: 0.3; }
        20% { opacity: 0.4; }
        40% { opacity: 0.5; }
        50% { opacity: 0.6; }
        60% { opacity: 0.5; }
        80% { opacity: 0.4; }
        90% { opacity: 0.3; }
    }

    @-webkit-keyframes fadein{
        10% { opacity: 0.3; }
        20% { opacity: 0.4; }
        40% { opacity: 0.5; }
        50% { opacity: 0.6; }
        60% { opacity: 0.5; }
        80% { opacity: 0.4; }
        90% { opacity: 0.3; }
    }

    &-meta {
      display: flex;
      align-items: center;
    }

    &-media {

      width: 66px;
      height: 66px;

      &--missing {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        >.icon {
          height: 40px;
          width: 66px;
        }
      }
    }

    &-image {
      width: 100%;
      height: 100%;
    }

    &-airline {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      flex-basis: 350px;
      align-items: center;

      &-logo {
        width: 24px;
        float: left;
        margin-right: 10px;
      }
    }

    &-price {
      @include headline-m();
      font-weight: 600;
      margin-bottom: 5px;
    }

    &-amenities-container {
      display: block;
      padding-left: 12px;
      padding-top: 10px;
    }

    &-amenities-title {
      @include paragraph-s();
      font-weight: 600;
      margin-bottom: 10px;
    }

    &-amenities-list {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
    }

    &-amenity {
      display: flex;
      flex-basis: 33%;
      line-height: 14px;
      font-size: 12px;
      white-space: nowrap;
      margin-bottom: 5px;
      padding-right: 5px;
      @media screen and (min-width: 1254px) {
        flex-basis: 25%;
      }

      &--disabled {
        opacity: 0.5;
      }

      .amenity__text {
        width:17vw;
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and (min-width: 1254px) {
          width: 14vw;
        }
        @media screen and (min-width: 1284px) {
          width: 15vw;
        }
      }
      .icon {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }
    }

    &-duration {
      @include paragraph-s();
      text-align: center;
      margin-top: -39px;
    }

    &-cabin {
      display: flex;
      border: 1px solid #ccc;
      background-color: #fff;
      padding: 8px;
      margin-top: 16px !important;
      border-radius: 5px;
      &.active.fs-lib__result--expanded{
        box-shadow: 0px 0px 4px 0px var(--color-button-fill-primary-pressed-medium);
        border: solid 1px var(--color-button-fill-primary-focus-medium);
      }
      > .clickable {
        cursor: pointer;
      }
    }
  }

  &__duration-timeline {
    display: flex;
    flex-grow: 1;
  }

  &__duration {
    flex-basis: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }

    h5 {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  &__timeline {
    &--horizontal {
      .moment__airport {
        @include paragraph-s();
      }
    }
  }

  &__cabin-name {
    background-color: var(--color-element-fill-primary-enabled-weak);
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    padding-left: 8px;
    margin-left: 4px;
  }

  &__cabin-container {
    padding-left: 0;
    flex: 1;
  }

  &__legs-tabs {
    flex: 0;
    display: block;
    margin-bottom: var(--space-l);

    .rh-tab-bar {
      background-color: transparent !important;
      padding: 0 !important;
    }

    .rh-tab-bar__list {
      flex-direction: column !important;

      @media screen and (min-width: 576px) {
        flex-direction: row !important;
      }
    }

    .rh-tab__item {
      padding: 4px var(--space-xs) !important;
      width: 100%;

      &--active {
        .fs-lib__leg-tab-info {
          color: var(--color-button-text-primary-enabled-medium);
        }
      }
    }
  }

  &__leg-tab {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &-logo>img {
      height: 30px;
    }

    &-info {
      padding: 0 20px;
      text-align: left;
    }

    &-times {
      @include paragraph-m();
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 3px;
    }

    &-airports {
      @include paragraph-xs();
      font-weight: 600;
      font-size: 14px;
    }
  }

  &__flight-actions {
    display: flex;
    flex: 1;
    justify-content: right;
  }

  &__leg-actions {
    padding: 20px var(--space-s);
  }

  &__leg-details-container {
    padding: var(--space-s) var(--space-s) 0 var(--space-s);
  }

  &__leg-title {
    @include headline-s();
    margin-bottom: var(--space-l);
    font-size: 18px;
  }

  &__leg-details {
    @include paragraph-s();
    list-style-type: none;
    margin-bottom: var(--space-l);

    @media screen and (min-wdth: 992px) {
      margin-bottom: var(--space-s);
    }
  }

  &__leg-amenity,
  &__leg-attribute {
    display: flex;
    padding-bottom: var(--space-s);
    margin-bottom: var(--space-s);
    border-bottom: solid 1px var(--color-element-border-primary-enabled-weak);
    overflow: hidden;
  }

  &__amenity {

    &-info {
      padding-right: 5px;
      flex: 1;
    }

    &-details {
      display: flex;
      flex-direction: column;
      padding-top: 2px;
      gap: 24px;
    }

    &-title {
      @include paragraph-m();
    }

    &-description,
    &-status-text {
      @include paragraph-xs();
      line-height: 18px;
      color: var(--color-text-primary-enabled-weak)
    }
    &-description:nth-of-type(n+2) {
      @include paragraph-xxs(0, 0, 300);
    }
    
    &-description:nth-of-type(n+3),
    &-cancellation-text:nth-of-type(n+3) {
      padding-top: 3px;
    }

    &-icon {
      width: 32px;
      flex: 0 0 32px;
      display: flex;
      justify-content: center;
      padding-top: 2px;

      svg {
        width: 16px;
        height: 18px;
        display: inline-block;
      }

    }

    &-status {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      text-align: right;
      max-width: 160px;

      &-icon {
        border-radius: 50%;
        width: 18px;
        height: 18px;
        position: relative;

        &--success {
          background-color: var(--color-button-fill-success-focus-medium);

          svg {
            width: 10px;
            height: 10px;
            display: inline-block;
            left: 4px;
            top: 4px;
            position: absolute;
          }
        }

        &--danger {
          svg {
            width: 18px;
            height: 18px;
            display: inline-block;
            left: 0;
            top: 0;
            position: absolute;
          }
        }

      }
    }

    &-standard,
    &-carry-on-allowance,
    &-loyalty {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
    &-cancellation {
      display: flex;
      flex-direction: column;
      padding-top: 24px;
      gap: 24px;
      
      div {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
      }

      &-title {
        @include paragraph-xs();
        line-height: 18px;
        color: var(--color-text-primary-enabled-weak)
      }

      &-text {
        font-size: 10px;
        font-weight: 300;
        line-height: 12px;
        color: var(--color-text-primary-enabled-weak)
      }
    }
  }

  &__upas {
    background: linear-gradient(180deg, #00a8c5 0%, #1c5090 100%);
    margin: 0 !important;
    color: var(--color-sidebar-text-primary-hover-strong);
    padding: 16px 16px 0 16px;

    >.col {
      padding: 0;
    }

    &-title {
      padding-right: var(--space-s) !important;

      h4 {
        @include headline-m();
      }
    }

    &-languages {
      padding-left: var(--space-s) !important;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        @include paragraph-s();
      }

      .rh-button__dropdown {
        @include paragraph-s();
        color: var(--color-sidebar-text-primary-hover-strong);

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }

    &--missing {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      >.icon {
        width: 100px;
        height: 60px;
        margin-bottom: 35px;
      }

      >p {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &-media-type-selector {

      margin-bottom: var(--space-s);

      list-style-type: none;
      padding-left: 0;
      display: flex;
      justify-content: center;

      button {
        @include paragraph-xs();
        width: 66px;
        height: 24px;
        font-weight: 500;

        &.fs-lib__upas-media-type-selector--selected {
          background: var(--color-button-fill-inverse-enabled-medium);
          color: var(--color-text-primary-enabled-weak) !important;
        }
      }
    }

    &-default-media-list-items {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      li {
        height: 45px;
        width: 45px;
      }

      img {
        width: 45px;
        height: 45px;

        &.fs-lib__upas-default-media {
          outline: solid 2px transparent;
          outline-offset: -2px;
          cursor: pointer;

          &--selected {
            outline: solid 2px var(--color-button-fill-inverse-enabled-medium);
          }
        }
      }
    }

    .simple-dropdown__item .button__text-primary {
      color: initial;
    }
  }

  &__selected-upa {
    &-media-icons {
      padding-bottom: 16px;
      min-width: 98px;
      flex: 0;
    }
    &-media {
      //flex: 0;
      width: inherit !important; //Bad encapsulation on a row style from the home component
      padding: 0 0 16px 32px;
    }
    &-data {
      flex: 1;
      padding: 0 0 16px 32px;
    }

    &-headline {
      @include headline-s();
      font-weight: 700;
      margin-bottom: 24px;
    }

    &-classification {
      @include paragraph-s();
      font-weight: 500;
      margin-bottom: 24px;
    }

    &-description {
      @include paragraph-s();
      margin-bottom: 24px;
    }

    &-cta {
      a {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: var(--color-button-fill-inverse-enabled-medium);
        display: flex;
        align-items: center;

        .icon {
          width: 16px;
          height: 16px;
          margin-left: var(--space-xs);
        }
      }
    }
  }

  &__top-row {
    padding: 10px 0 26px;
  }
  &__upa-media-carousel {
    &-container {
      display: flex;
      height: 260px;
      flex-direction: column;
    }

    &-expand {
      position: absolute;
      bottom: 12px;
      right: 12px;
      color: var(--color-button-fill-inverse-enabled-medium);
      cursor: pointer;
    }

    &-element {
      position: relative;
      height: 260px;
      max-width: fit-content;
      margin-bottom: 12px;
      border: solid 1px var(--color-button-fill-inverse-enabled-medium);
      text-align: center;
      overflow: hidden;
    }

    &-image {
      height: 100%;
    }

    &-switch-button {
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: transparent !important;
      border-color: transparent !important;
      color: transparent !important;
      font: 0/0 a;
      height: 100%;
      width: 30%;
      -webkit-appearance: none;
      outline: transparent;

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }
    }

    &-embed {
      width: 100%;
      height: 100%;
      border: none;
    }

    &-indicator {
      list-style: none;
      margin-left: 0;
      height: 16px;
      width: 100%;
      margin: 0 auto 13px;
      text-align: center;

      &-dot {
        display: inline-block;
        height: 9px;
        width: 9px;
        border: solid 1px #8cadcc;
        background: #8cadcc;
        border-radius: 50%;
        margin: 5px;
        cursor: pointer;

        &--active {
          background: var(--color-element-fill-inverse-enabled-weak);
          border-color: var(--color-element-fill-inverse-enabled-weak);
        }
      }
    }
  }

  &__upa-media-dialog {
    &--active {
      overflow: hidden;

      .fs-lib__upa-media-dialog-modal {
        visibility: visible;
        opacity: 1;
      }
    }

    &-overlay {
      background: rgba(229, 229, 229, 0.9);
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: 999;
      right: 0;
      left: 0;
    }

    &-modal {
      position: fixed;
      top: 4vh;
      left: calc((100vw - calc(800px + 2rem))/2);
      width: calc(800px + 2rem);
      border: 1px solid #eee;
      border-radius: 4px;
      padding: 1rem;
      text-align: center;
      z-index: 1000;
      background-color: var(--color-element-fill-inverse-enabled-weak);
      color: var(--color-text-primary-enabled-medium);
      align-items: center;
      display: flex;
      flex-direction: column;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s linear;

      &--file {
        height: 95vh;
      }

      &--resource {

        height: 25vw;

        iframe {
          width: 100%;
          height: 450px;
          margin-top: 2rem;
          border: none;
        }
      }
    }

    &-title {
      align-self: flex-start;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    &-description {
      align-self: flex-start;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &-preview {
      margin: 1rem;
      max-width: 800px;
      max-height: 500px;
    }

    &-thumbnails {
      display: flex;
      flex-direction: row;
      list-style-type: none;
      justify-content: space-between;
      gap: 3rem;
      width: 800px;
    }

    &-thumbnail {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }

    &-close {
      top: var(--space-s);
      right: var(--space-s);
      position: absolute;
      cursor: pointer;
    }
  }

  &__review {
    padding: var(--space-m) var(--space-xl) var(--space-xl) var(--space-xl);
    display: block;

    &-title {
      @include headline-s();
      color: var(--color-text-primary-enabled-weak);
      font-weight: 600;
    }

    &-description {
      @include paragraph-s();
      margin-bottom: var(--space-m);

      @media screen and (min-width: 576px) {
        margin-bottom: 0;
      }

      a {
        color: var(--color-button-fill-primary-enabled-medium);
        font-weight: 600;
      }
    }

    &-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }

    &-spinner {
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      background-color: rgba(151, 160, 175, 0.5);
      display: grid;
      place-items: center;
    }
  }

  .date-input__container .input {
    text-transform: none !important;
  }

  .input--error {
    border-color: var(--color-button-border-danger-enabled-medium) !important;

    &:focus {
      box-shadow: 0px 0px 4px 0px var(--color-button-border-danger-enabled-medium);
    }
  }

  &__next-page-message {
    @include paragraph-s();
    text-align: center;
  }

  .field--s select,
  rh-ui-field.field--s rh-ui-simple-dropdown .simple-dropdown__item>button {
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 0;
    height: var(--space-l);
    padding-top: var(--space-xxs);
    padding-bottom: var(--space-xxs);
  }

  rh-ui-date-picker-solo.field--s .rh-button {
    height: 32px;
    width: 32px;
  }

  &__no-upa-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__no-upa-bar {
    width: 100%;
    height: 18px;
    display: flex;
    justify-content: center;
    background-color: #DDD;;
    margin: 16px 12px 0;
  }

  &__no-upa-text {
    background-color: white;
    font-size: 14px;
    padding: 0 16px;
  }
}

.rh-button--page {
  border: 1px solid #aaaaaa;
  color: #aaaaaa;
  min-width: 50px;
  &--current {
    min-width: 50px;
    cursor: default;
    pointer-events: none;
  }
}

/* === end of styles for "Hub Flight Search" library */
