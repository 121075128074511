.saved-views {
  position: relative;
  z-index: 2;
}
.saved-views__container {
  @include default-transition();
  list-style: none;
  margin: 0;
  padding: 0;
  height: calc(100vh - (320px + 31px));
  overflow-x: hidden;
  width: 100%;

  &--extended {
    height: calc(100vh - ((384px - 128px) + 31px));
  }
}
.saved-views__actions {
  @include default-property-transition(opacity);
  display: flex;
  position: absolute;
  bottom: -31px;
  z-index: 10;
  width: 100%;
}
.saved-views__action {
  @include btn__action;
  border-top: 1px solid $border-color;
  border-right: 1px solid $border-color;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  &.saved-views__action--edit {
    border-left: 1px solid transparent;
    border-right: none;
  }
}
.saved-views__views {
  @include antialiased(600, 600);
  font-size: 14px;
  margin: 00;
  padding: 0;
  width: 100%;
  .placeholder {
    list-style: none !important;
  }
}
.saved-views__view,
.saved-views__child {
  position: relative;
  font-size: 14px;
  list-style: none;
  height: 36px;
  padding: 0 0 0 var(--space-xl);

  &:hover {
    background-color: var(--color-sidebar-fill-primary-hover-medium);
    color: var(--color-sidebar-text-primary-enabled-medium);
    cursor: pointer;
  }

  .saved-view__delete {
    top: 8px;
    left: 15px;
    right: unset;
    cursor: pointer;
    opacity: 0;
    color: var(--color-sidebar-text-primary-enabled-medium);
  }

  div.handle {
    opacity: 0;
    left: 170px;
  }
  &:hover {
    .saved-view__delete,
    div.handle {
      opacity: 1;

      i {
        background: var(--color-sidebar-text-primary-enabled-medium);
      }
    }
  }
}
input.saved-view__input {
  background: none;
  border-color: transparent;
  box-shadow: none;
  box-sizing: border-box;
  font-size: 14px;
  padding: 0 2px;
  width: 100%;
  border: solid 1px transparent;

  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  font-family: Inter, sans-serif;
  color: var(--color-sidebar-text-primary-enabled-medium);
  text-align: left;

  &:hover {
    cursor: pointer;
  }

  .saved-views__view--active &,
  .saved-views__child--active & {
    color: $link-hover;
  }

  &.saved-view__input--editing {
    width: 80%;
    &:hover,
    &:focus {
      border: solid 1px var(--color-sidebar-text-primary-enabled-medium);
      outline: none;
      cursor: text;
    }
  }
}
