/* ==========================================================================
   Sign In
   ========================================================================== */
.login__container {
  background-color: $bg-gray;
  height: 100vh;
}
.form-box__container {
  @include default-dropshadow;
  background-color: $white;
  margin: 0 auto;
  padding: 40px 60px;
  width: 535px;
}
.sign-in,
.pw-reset {
  font-size: 18px;
  margin: 0 auto;
  padding-top: 132px;

  &.column,
  .column {
    padding: 0;
  }
  .rh-logo {
    margin: 0 auto 30px;
    height: 60px;
    text-align: center;
    width: 160px;

    svg {
      fill: $brand-blue;
    }
  }
  .title {
    font-size: 28px;
    margin: 0 0 20px;
    width: 335px;
  }
  .sub-title {
    color: $medium-gray;
    font-size: 15px;
    margin-bottom: 0;
    width: 335px;
  }
  input.text {
    font-size: 18px;
    line-height: 18px;
    padding: 12px;
    width: 100%;
  }
  dl dt,
  dl dd {
    margin-bottom: 15px;
  }
  dt {
    font-size: 18px;
    font-weight: normal;
    line-height: 18px;
  }
  .error,
  .success {
    font-size: 14px;
  }
  a.contact {
    font-size: 15px;
    font-weight: normal;
  }
}
.login__submit {
  font-size: 18px;
  line-height: 18px;
  height: auto;
  float: right;
  padding: 16px;
  width: auto;
  .ng-dirty.ng-invalid & {
    opacity: 0.75;
  }
}
.login__switch-link {
  display: block;
  font-size: 15px;
  font-weight: normal;
  margin: 20px auto;
  text-align: center;
  width: 140px;
}
.bio {
  display: flex;
  align-items: center;
}
.bio__image {
  width: 37px;
  margin: 0 20px 0 0;
}
.bio__text-content {
  flex: 1;
}
