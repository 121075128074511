/* ==========================================================================
   Footer
   ========================================================================== */
@import 'typography';

   .footer {
    @include padded-row;

    .row {
      margin-right: auto; margin-left: auto;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      gap: var(--space-s);
    }
  }
  #footer {
    color: var(--color-text-primary-enabled-weak);
    margin: 0 auto;
    padding: var(--space-s) 0 0
  }
  .footer__logo {
    height: 38px;
    width: 97px !important;
    margin-top: var(--space-xxs);

    svg {
      height: 38px; width: auto;
    }
  }
  .rh-logo__svg {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
  }
  .footer__links {
    @include paragraph-xs(0, 0, 400);
    @include no-bullet;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    gap: var(--space-s);
  }
  .footer__item {
    .rh-link {
      color: var(--color-text-primary-enabled-weak);
    }
  }
