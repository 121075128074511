.reveal-modal-bg {
    background-color: #000000;
    background-color: rgba(0,0,0,0.45);
    display: none;
    position: fixed; right: 0; top: 0; bottom: 0; left: 0;
    z-index: 1004;
}
.reveal-modal {
  border-radius: 3px;
  display: none;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 1005;
  left: 0;
  background-color: #FFFFFF;
  padding: 1.875rem;
  border: solid 1px $border-color;
  @include default-dropshadow;

  @media screen and (min-width: $bp-medium) {
    top: 6.25rem;
  }
  p {
    font-size: 18px;
    line-height: 22px;
  }
  .options,
  .reveal-modal__options {
    display: inherit !important;
    margin: 0 !important;
    text-align: center;
    a {
      margin-right: 10px;
      &:last-child {
        margin: 0;
      }
    }
    .cb {
      display: inline-block;
      float: inherit;
      margin: 10px 0 0;
      padding: 10px 0 0;
    }
  }
  &.small {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    max-width: 62.5rem;
  }
  &.media {
    //margin-left: -410px;
    outline: 0;
    padding: 10px 45px;
    max-width: 820px;
    #icons & {
      //margin-left: -225px;
      padding: 0;
      width: 450px;
      height: 450px;
    }
    .close-reveal-modal,
    .arrow svg {
      background: #FFF;
      color: $medium-gray;
      transition: color 200ms ease-out;
    }
    .close-reveal-modal {
      font-size: 16px;
      font-weight: normal;
      position: absolute;
      line-height: 16px;
      top: 0;
      right: 0;
      padding: 10px;
      &:hover {
        color: $dark-gray;
      }
    }
    .media-content {
      position: relative;
      text-align: center;
      img {
        max-width: 100%;
        max-height: 78vh;
      }
      #icons &,
      #photos &,
      #upas_media & {
        margin-bottom: 10px;
      }
      #icons & {
        display: table;
        margin: 0;
        width: 450px;
        height: 450px;
        figure {
          display: table-cell;
          text-align: center;
          vertical-align: middle;
        }
      }
      #photos &,
      #upas_media & {
        display: table;
        min-height: var(--space-xxl);
        width: 100%;
        figure {
          display: table-cell;
          text-align: center;
          vertical-align: middle;
        }
      }
    }
    .media-title {
      color: #000;
      font-size: 16px;
      line-height: 16px;
      margin: 0;
      text-align: center;
      #icons & {
        position: absolute;
        left: 10px;
        bottom: 10px;
        width: 430px;
      }
    }
    nav {
      ul {
        @include no-bullet;
        margin: 0;
      }
    }
    .arrow {
      position: absolute;
      top: 0;
      width: 45px;
      height: 100%;
      &:hover {
        i {
          color: $dark-gray;
        }
      }
      svg {
        font-size: 24px;
        line-height: 24px;
        padding: 10px;
        position: absolute;
        margin-top: -22px;
        top: 50%;
        width: 44px;
        height: 44px;
        cursor: pointer;
      }
      &.prev {
        left: 0;
        svg {
          left: 0;
        }
      }
      &.next {
        right: 0;
        svg {
          right: 0;
        }
      }
    }
    #videos &,
    &.videos {
      .arrow {
        top: 30%;
        width: 10%;
        height: 40%;
      }
    }
    #tours &,
    &.tours {
      .arrow {
        top: 30%;
        width: 10%;
        height: 40%;
      }
    }
  }
}
.fade {
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.fade.in {
  opacity: 1;
}
