/* ==========================================================================
   Variables
   ========================================================================== */

$bp-small: 384px;
$bp-medium: 768px;
$bp-large: 1024px;

$global-width: 1000px;
$global-min-width: 768px;
$header-height: 88px;
$footer-height: 211px;
$menu-panel-width: 129px;
$fixed-grid-item-width: 312px;
$min-height-content: 400px;

/* ==========================================================================
   Mixins
   ========================================================================== */

@mixin no-bullet {
  list-style: none;
  margin-left: 0;
}

@mixin group {
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin default-transition {
  transition: all 200ms ease-out;
}
@mixin default-property-transition($property) {
  transition: $property 200ms ease-out;
}

@mixin antialiased($weight1, $weight2) {
  font-weight: $weight1;
  -webkit-font-smoothing: antialiased;
  @media screen and (-webkit-min-device-pixel-ratio: 2) {
    font-weight: $weight2;
    -webkit-font-smoothing: inherit;
  }
}

@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin default-dropshadow {
  box-shadow: 0 4px 3px -3px rgba(black, .1);
}
@mixin default-dropshadow-dark {
  box-shadow: 0 4px 3px -3px rgba(black, .3);
}
@mixin dropdown-dropshadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .4);
}
@mixin padded-row {
  padding-left: 18px; padding-right: 18px;
}
@mixin input-focused {
  border: solid 1px $link-hover;
  box-shadow: inset 0px 1px 0 0 #eeeeee,
    0 0 0 1px $link-hover;
  outline: none;
}

@mixin rh-hover {
  &:hover {
    cursor: pointer;
    color: $link-hover;
    fill: $link-hover;
    .icon {
      fill: $link-hover;
    }
  }
}
