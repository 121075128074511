.program__hd {
  border-bottom: 1px solid $border-color;
  line-height: 18px;
  margin-bottom: 7px; padding-bottom: 7px;
}
.program__location {
  font-size: 18px; font-weight: 600;
  margin-right: 5px;
}
.program__direction {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;

  .icon {
    height: 16px; width: 16px;
  }
}
.program__container {
  padding: 0 0 14px;
}
.program__meta {
  padding: 0 0 4px;
}
.program__title {
  display: inline-block;
  font-size: 18px;
  width: 346px;
}
.program__dates,
.program__restrictions {
  display: inline-block;
  font-size: 14px;
  padding: 4px 0 0;
}
.program__dates {
  margin-right: 23px;
}
.program__restriction {
  color: $medium-gray;
  margin: 0 7px 0 0;
}
