$tile-width-mobile: 294px;
$tile-width: 324px;

.ua-tile__container {
  width: $tile-width-mobile;
}
.ua-tile {
  position: relative;
  @include group;
  transition: border 200ms ease-out, opacity 200ms ease-out;

  &:hover,
  &.active,
  &.pending {
    .ua-tile__actions {
      visibility: visible;
      opacity: 1;
    }
  }
  .draft &.unsaved {
    .ua-tile__actions {
      li {
        width: 42%;
        &.action-more {
          width: 16%;
        }
      }
    }
  }
  .disabled &.unsaved {
    .ua-tile__actions {
      li {
        width: 84%;
        &.action-more {
          width: 16%;
        }
      }
    }
  }
  &.loader {
    &:hover {
      border-color: transparent;
    }
    #photos & {
      > div {
        border: 1px solid #E0E0E0;
        margin: 22px 4px 0;
        position: relative;
        width: 100px;
        height: 100px;
      }
    }
    #icons & {
      border: 1px solid #E0E0E0;
      min-height: 0;
      > div {
        position: relative;
        height: 104px;
      }
    }
  }
  figure {
    margin: 0;
  }
}
.ua-tile__meta {
  @include no-bullet;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}
.ua-tile__id,
.ua-tile__category,
.ua-tile__airline {
  color: var(--color-text-primary-enabled-weak);
  display: inline-block;
  font-size: 11px;
  line-height: 1;
  margin-bottom: 8px; margin-right: 10px;
  text-transform: uppercase;
}
.ua-tile__warning {
  display: inline-block;
  height: 12px;
  overflow: visible;
  vertical-align: middle;

  .beware {
    font-size: 14px;
    height: 14px; width: 14px;
    fill: $red;
  }
}
.ua-tile__category-name {
  margin-right: 8px;
  white-space: nowrap;
}
.ua-tile__content {
  height: 424px; width: $tile-width-mobile;
}
.ua-tile__media-element {
  position: relative;
  background-size: 100%;
  background-position: center;
  background-color: white;
  height: $tile-width-mobile; width: $tile-width-mobile;
  margin-bottom: 12px;

  &.ua-tile__media-element--rich {
    background-color: black;
  }
}
.ua-tile__media-img {
  aspect-ratio: 1/1;
  width: $tile-width-mobile;
}
.ua-tile__video-embed {
  margin: 71px auto;
  width: $tile-width-mobile;
}
.ua-tile__more-indicator {
  position: absolute; right: 6px; bottom: 6px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  color: var(--color-text-primary-enabled-weak);
  height: 32px; width: 32px;
  font-size: 14px;
  line-height: 32px;
  overflow: hidden;
  text-align: center;
}
.ua-tile__icon {
  position: absolute; top: 50%; left: 50%;
  margin-top: -21px; margin-left: -21px;
  height: 42px; width: 42px;
}
.ua-tile__no-icon-image {
  height: 283px; width: 283px;
  margin: 15px auto 0;
}
.ua-tile__headline {
  font-size: 20px; font-weight: 500;
  line-height: 1.2;
  margin: 12px 0 5px;
  padding: 0 8px;
}
.untranslated { // .ua-tile.untranslated
  .ua-tile__headline, .ua-tile__description {
    color: var(--color-text-primary-enabled-weak);
    opacity: .5;
  }
}
.ua-tile__description,
.ua-tile__caption {
  font-size: 14px;
  line-height: 1.3;
  padding: 0 8px;
}

.ua-tile__alt_text {
  font-size: 14px;
  line-height: 1.3;
  padding: 0 8px;
  color: var(--color-text-primary-enabled-weak);
}
.ua-tile__description--long {
  height: 92px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ua-tile__caption--long {
  height: 100px;
  padding-bottom: 10px;
  overflow-y: scroll;
}
.ua-tile__caption {
  &.ua-tile__caption--translated {
    color: $dark-gray;
    opacity: 1;
  }
  &.ua-tile__caption--untranslated {
    color: var(--color-text-primary-enabled-weak);
    opacity: .5;
  }
}

.tile-carousel {
  position: relative;
  height: 310px;
}
.tile-carousel__switch-button {
  position: absolute; top: 0; bottom: 0;
  background-color: transparent !important;
  border-color: transparent !important;
  color: transparent !important;
  font: 0/0 a;
  height: 100%; width: 30%;
  -webkit-appearance: none;
  outline: transparent;
  &:first-child {
    left: 0;
  }
  &:last-child {
    right: 0;
  }
}
.dot-indicator {
  @include no-bullet;
  height: 16px; width: 100%;
  margin: 0 auto 13px;
  text-align: center;
}
.dot-indicator__arrow {
  display: inline-block;
  margin-right: 5px; margin-left: 5px;
}
.tile-carousel__button {
  fill: var(--color-text-primary-enabled-weak);
  &:hover {
    cursor: pointer;
    fill: $brand-blue;
  }
}
.dot-indicator__dot {
  display: inline-block;
  height: 9px; width: 9px;
  border: solid 1px var(--color-text-primary-enabled-weak);
  border-radius: 50%;
  margin: 5px;
  &:hover {
    cursor: pointer;
    border-color: $brand-blue;
    background-color: $brand-blue;
  }
}
.dot-indicator__dot--active {
  background-color: var(--color-text-primary-enabled-weak);
}
.tile-carousel__text--spacer {
  padding-top: 29px;
}
.ua-tile__actions {
  position: relative;
  @include no-bullet;
  @include group;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-out;
  display: flex;
}
.ua-tile__action {
  border: 1px solid $light-gray;
  flex: 1;
  &:last-child {
    border-left: 0;
  }
  .published &,
  .disabled & {
    width: 42%;
  }
  .pending & {
    width: 84%;
  }
  &.ua-tile__action--show-disable {
    width: 84%;
  }
  #team & {
    border: 0;
    float: none;
    a {
      padding: 10px 0;
    }
  }
  &.ua-tile__action--more {
    flex: 0 0 16%;
    > button {
      padding: 8px 0;
    }
  }
}
.more-menu {
  position: absolute; right: 0; bottom: 39px;
  width: 42%;
  transition: top 0.2s ease-out;

  &.ng-hide,
  &[hidden] {
    top: 0;
    z-index: -1;
  }
}
.ua-tile__button {
  @include btn__action;
  width: 100%;
}
.more-menu-list {
  @include no-bullet;
  border-left: 1px solid $light-gray;
  border-right: 1px solid $light-gray;
}
.more-menu-item {
  border-top: 1px solid $light-gray; border-right: none;
  border-bottom: 1px solid $light-gray; border-right: none;
  float: none;
  width: 100% !important;
}
.delete {
  background: transparent;
  color: var(--color-text-primary-enabled-weak);
  bottom: 124px;
  width: auto;
  padding: 0;
  right: 3px;
  &:focus {
    outline: none;
  }
  &:focus,
  &:hover {
    background: transparent;
    color: $dark-gray;
  }
}
.ua-tile--pending {
  border: 1px dashed var(--color-element-border-primary-enabled-strong);
  background: var(--color-background-primary-enabled-medium);
  color: var(--color-text-primary-enabled-medium);
  min-height: 487px; // height of a UPA tile
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-s);
  width: 100%;
  .rh-loader-container {
    padding-top: 76px;
  }
}
.ua-tile__pending-content {
  color: var(--color-text-primary-enabled-medium);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--space-xs);
  @include default-transition;
  &:hover {
    text-decoration: underline;
  }
}
.tile-pending__title {
  @include paragraph-s(0,0, 600);
}
.tile-pending__description {
  @include paragraph-xs(0, 0, 400);
}
.ua-tile-moved__container {
  margin: auto;
}
