.language-picker {
  position: relative;
  display: inline-block;
  @include antialiased(500, 400);
}
.language-picker__current {
  cursor: pointer;
  position: relative;
  background-color: #fff;
  color: $dark-gray;
  display: block;
  font-size: 18px; font-weight: 600;
  line-height: 28px;
  padding: 6px 0;
  height: 36px; width: 30px;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
  z-index: 20;
  @include default-transition;

  &:hover {
    color: $link-hover;
  }
  &.open {
    background-color: $link-hover;
    color: #fff;
  }
}
.language-picker__list {
  max-height: 340px; min-width: 130px;
  overflow-y: hidden;
  padding: 4px 10px 4px 0;
  z-index: 100;
}
.language-picker__list--overflow {
  overflow-y: scroll;
}
.language-picker__item {
  color: $dark-gray;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;

  &:hover,
  &:hover .language-picker__code {
    color: $link-hover;
    cursor: pointer;
  }
  &.language-picker-item__selected {
    .language-picker__check {
      opacity: 1;
    }
  }
}
.language-picker__check {
  opacity: 0;
  text-align: center;
  padding: 4px 6px;
  width: 30px;
  .icon-Check {
    height: 12px; width: 14px;
  }
}
.language-picker__code {
  color: $medium-gray;
  font-weight: 600;
  padding: 4px 12px 4px 0;
  text-transform: uppercase;
  width: 30px;
}
.language-picker__name {
  padding: 4px 12px 4px 0;
}
