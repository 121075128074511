.markets {
  @include group;
  @include no-bullet;
  margin: 0;
}

.market {
  border: 2px solid $light-gray;
  border-top-width: 5px;
  margin: 0 0 25px;
  padding: 0;

  &.sortable-ghost {
    border-style: dashed;
    border-top-width: 2px;

    .market__details {
      visibility: hidden;
    }
  }
}

.market__details {
  @include group;
  background-color: #fff;
  border-bottom: 1px solid $light-gray;
  position: relative;

  .market__block {
    font-size: 18px;
    height: 50px;
    line-height: 30px;
    padding: 10px 40px 10px 20px;
    vertical-align: middle;
  }

  .market__name {
    @include text-ellipsis;
    font-size: 18px;
    font-weight: 600;
  }
}

.market__handle {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -10px;
  display: block;
  cursor: move !important;
  z-index: 3;
}

.market-utas {
  position: relative;

  ul {
    @include no-bullet;
  }
}

.market-utas__categories-list {
  border-right: 1px solid $border-color;
  box-shadow: 4px 0 3px -3px rgba(black, .1);
  padding-top: 40px;
  padding-bottom: 25px;
  width: 182px;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.market-utas__category {
  color: $dark-gray;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  @include text-ellipsis;
  width: 100%;
}

.market-utas__fare-brands {
  width: calc(100% - 182px);
}

.market-utas__fare-brands--scroll {
  overflow-x: scroll;
}

.market-utas__fare-brands-list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-bottom: 12px;
  width: 100%;
}

.market-utas__fare-brand {
  padding: 0 6px;
  text-align: left;
  width: 162px;
}

.market-utas__fare-brand-title {
  border-bottom: 2px solid $border-color;
  font-size: 14px;
  font-weight: 600;
  height: 35px;
  line-height: 1;
  margin: 0 0 5px;
  padding: 12px 0 0;
  @include text-ellipsis;
  width: 100%;
}

.market-utas__uta {
  font-size: 14px;
  font-weight: 600;
  height: 24px;
  line-height: 24px;
  @include text-ellipsis;
  width: 100%;

  a,
  span {
    color: $dark-gray;

    &.market-uta__na {
      color: $medium-gray;
      font-weight: normal;
    }
  }

  .market-uta__link {
    color: $link-color;

    &:not(:last-child):after {
      content: ', '
    }
  }

  .market-uta__span {
    &:not(:last-child):after {
      content: ', '
    }
  }
}

.market-manager {
  position: absolute;
  background: #fff;
  margin: -13px 0 0 10px;
  z-index: 2;

  @include dropdown-dropshadow;
  @include group;
}

.market-manager__list {
  @include group;
  margin: 3px 0 0;
  width: 100%;
  padding: 6px 0;
}

.market-manager__item {
  position: relative;
  font-size: 16px;
  line-height: 1;
  padding: 12px 24px;
  white-space: nowrap;

  @media screen and (min-width: $bp-medium) {
    padding: 6px 24px;
  }

  svg {
    height: 14px;
    width: 14px;
    opacity: 0;
    position: absolute;
    left: 6px;
  }

  &.selected {
    color: $brand-blue;

    svg {
      fill: $brand-blue;
      opacity: 1;
    }
  }

  &:hover {
    color: $brand-blue;
    cursor: pointer;
  }
}

.market-manager__field {
  padding: 8px;
}

.market-manager__size {
  @include group;

  &:not(:last-child) {
    margin-bottom: 6px;
  }

  .market-manager__size-input {
    float: left;
    width: 45px;
  }

  label {
    float: left;
    line-height: 34px;
    width: 34px;
  }
}

.market-manager__options {
  padding: 8px;
}

.market-manager__btn {
  width: 100%;
}

.market-rules {
  border-top: 1px solid $border-color;
  @include group;
  padding: 20px 16px;

  .rules-builder {
    .list {
      width: calc(50% - 15px); // half width of "or" button
    }

    .rules {
      @include no-bullet;

      >li {
        margin-bottom: 20px;
        position: relative;
        @include group;
      }
    }
  }
}

.market-rules__hd {
  float: left;
  font-size: 16px;
  font-weight: 400;
  width: 168px;
}

.market-rules__component {
  float: left;
  width: calc(100% - 168px);
}

.market-rule-form {
  @include group;
  display: block;

  &:nth-child(2) {
    margin-top: 10px;
  }
}