/* ==========================================================================
   UPAs: Summary - Flight Search Results
   ========================================================================== */

.segments {
  @include group;
  @include no-bullet;
  margin: 0;
}
.segment {
  margin: 0 0 25px; padding: 0;
}
.segment-details {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $light-gray;
}
.segment-block {
  float: left;
  font-size: 12px;
  height: 58px;
  line-height: 38px;
  padding: 10px 20px;
  vertical-align: middle;
}
.segment-block__stacked {
  line-height: 16px;
  padding: 13px 10px;
  text-align: left;
}
.segment-details__flight-number {
  font-size: 22px; font-weight: 700;
  padding-left: 0;
  width: 80px;
}
.segment-details__time {
  font-size: 14px;
  font-weight: 700;
}
.segment-details__duration {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin: 0 5px;
}
.segment-details__duration-bg {
  position: relative;
  background-color: $bg-gray;
  border-radius: 3px;
  display: inline-block;
  line-height: 1;
  padding: 4px 8px;
  &:before {
    content: ' ';
    position: absolute; left: -9px; top: 50%;
    display: inline-block;
    border-top: 4px solid $bg-gray;
    height: 0; width: 9px;
    margin-top: -1px;
  }
  &:after {
    content: ' ';
    position: absolute; right: -9px; top: 50%;
    display: inline-block;
    border-top: 4px solid $bg-gray;
    height: 0; width: 9px;
    margin-top: -1px;
  }
}
.segment-details__subfleet {
  font-size: 14px;
}
.segment__program {
  display: block; padding-top: 10px;
}
.flight-upa {
  @media screen and (min-width: 1280px) {
    max-width: $fixed-grid-item-width;
  }
  .flight-upa-basic-link {
    display: block;
    margin: 10px; padding: 10px;
  }
  &:nth-of-type(3n+1) {
    .grid-item {
      margin-left: -1px;
      &:hover,
      &.active,
      &.pending {
        @media screen and (max-width: 1280px) {
          border-left-color: transparent;
        }
      }
    }
  }
  &:nth-of-type(3n+3) {
    margin-right: -1px;
    .grid-item {
      &:hover,
      &.active,
      &.pending {
        @media screen and (max-width: 1280px) {
          border-right-color: transparent;
        }
      }
    }
  }
  &:nth-of-type(n+4) {
    .grid-item {
      &:hover,
      &.active,
      &.pending {
        @media screen and (max-width: 1280px) {
          border-top-color: $light-gray;
        }
      }
    }
  }
  .grid-item {
    margin-bottom: -1px;
    &:hover,
    &.active,
    &.pending {
      @media screen and (max-width: 1280px) {
        border-top-color: transparent;
      }
    }
    &.pending {
      margin-bottom: -1px;
    }
  }
}
.segment-no-upas {
  color: $medium-gray;
  font-size: 18px; font-weight: 300;
  padding: 50px 0;
  text-align: center;
}
