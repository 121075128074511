.ua-filters {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  max-height: 36px;
}
.ua-filters__list {
  display: flex;
  @include no-bullet;
  gap: var(--space-s);
}
.ua-filters__button {
  background: none;
  height: 36px;
  outline: none;
  padding: 5px 4px 0;
  @include default-transition;
  .icon {
    fill: $dark-gray;
    height: 24px; width: 24px;

    &-filters {
      margin-top: 3px;
      height: 18px; width: 18px;
    }
  }
  &--activated {
    .icon {
      color: var(--color-button-text-primary-enabled-medium);
    }
  }
  &:hover {
    .icon {
      fill: $link-hover;
    }
  }
  &--open {
    background-color: $link-hover;
    .icon {
      fill: #fff !important;
    }
  }
  &--no-input {
    &--activated {
      background-color: $green;
      .icon {
        fill: #fff;
      }
      &:hover {
        background-color: $green;
        .icon {
          fill: #fff;
        }
      }
    }
  }
}
.filter__component--open {
  .ua-filters__button--no-input {
    background-color: $link-hover;
    .icon {
      fill: #fff;
    }
    &--activated {
      background-color: $green;
      .icon {
        fill: #fff;
      }
    }
  }
}
.clear-filters {
  color: $green;
  font-size: 14px; font-weight: 600;
  vertical-align: middle;
  .icon {
    fill: $green;
    height: 12px; width: 12px;
    vertical-align: middle;
  }
  span {
    margin-left: 4px;
    text-transform: uppercase;
    vertical-align: middle;
  }
  &:hover {
    .icon {
      fill: $link-hover;
    }
  }
}

/* filter component =======
=========================*/
.filter__component {
  position: relative;
}
.filter__search-container {
  position: absolute; top: 0; left: 0; z-index: 101;
  height: auto; width: 180px;
  .typeahead__input,
  .typeahead__hint {
    padding: 3px 6px;
    height: 26px;
  }
}
.filter__panel {
  position: absolute; top: 37px; z-index: 100;
  @include dropdown-dropshadow;
  background-color: #fff;
  padding: 14px;
  min-width: 158px; max-width: 1200px;
  @media screen and (min-width: $bp-medium) {
    width: 158px;
  }

  &--actions {
    padding: 0;
    .filter__columns {
      padding: 12px 14px 0;
    }
  }
  &--scroll {
    max-height: calc(30px * 10);
    overflow-y: scroll; overflow-x: hidden;
    .filter__item {
      height: 25px;
    }
  }
  &--rtl {
    right: 0;
  }
}
.filter__columns,
.filter__list {
  @include no-bullet;
  &--single {
    padding: 12px 14px 0;
  }
}
.filter__columns {
  display: flex;
  flex-flow: column wrap;
  overflow-y: scroll;
  @media screen and (min-width: $bp-medium) {
    flex-flow: row wrap;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-height: 378px;
  }
}
.filter__column {
  margin-bottom: 10px;
  overflow: hidden;
  @media screen and (min-width: $bp-medium) {
    margin-right: 20px;
  }
}
.filter__item {
  font-size: 14px;
  margin: 0 0 5px;
  .rh-cb__label {
    @include text-ellipsis;
    width: 100%;
  }
}
.filter__hd {
  color: $medium-gray;
  font-size: 14px;
  font-weight: 400;
  margin: 1px 0 5px;
  text-transform: uppercase;
  &--checkbox {
    margin-bottom: 8px;
  }
  .rh-cb__label {
    @include text-ellipsis;
    width: 100%;
  }
}
.filter__button {
  background-color: transparent;
  font-size: 14px;
  text-align: left;
  padding: 1px;
  white-space: nowrap;
  &:hover {
    color: $link-hover;
  }
}
.filter__list-empty {
  text-align: center;
  padding: 14px 0;
}
.filter__actions-bar {
  background-color: $bg-gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0; padding: 3px 3px 3px 14px;

  &--single {
    justify-content: flex-end;
  }
  .rh-cb__label {
    font-size: 14px;
  }
}
