.filter__panel.form-rh {
  fieldset {
    width: 100%;
  }
  header {
    font-size: 20px;
    line-height: 20px;
    padding: 12px 12px 6px;
  }
  dl {
    padding: 0 12px 1px;
    dt,
    dd {
      width: 100%;
    }
    dt {
      color: $medium-gray;
      float: none;
      margin: 0;
      padding: 10px 0;
    }
    dd {
      margin-bottom: 10px;
      &.cbs {
        margin-top: -10px;
      }
      &.filters__filter-container--groupBy {
        margin-top: 0;
      }
      .select {
        margin: 10px 0 0;
        select {
          margin: 0;
        }
      }
    }
  }
  .cb {
    margin: 0;
    padding-bottom: 0;
    width: 50%;

    label {
      @include text-ellipsis;
      padding-right: 10px;
      width: 100%;
    }
  }
}
.filters__airport-search {
  position: relative;
  display: block;
  height: 36px; width: 100%;
}
