.multicheck-dropdown {
  position: relative;

  .rh-glyphs-down-arrow,
  .rh-glyphs-close {
    position: absolute; top: 10px; right: 10px;
  }
}
.multi-check-search {
  .text {
    font-size: 16px;
    height: 36px;
  }
}
.multi-check-list {
  background-color: white;
  max-height: 260px; width: 100%;
  padding: 5px 0 0;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 100;
  .none {
    padding: 10px 5px 10px 10px;
  }
  .new-cb {
    margin-bottom: 0; padding: 5px 5px 5px 10px;

    label {
      padding-left: 30px;

      &:before {
        left: 10px;
      }
    }
  }
}
.multicheck-dropdown__floating {
  .multi-check-list {
    position: absolute; top: 26px;
    @include dropdown-dropshadow;
  }
}
