.typeahead__container {
  position: absolute;
  width: 100%;
  z-index: 1;
}
.typeahead__input {
  display: inline-block;
  flex: auto;
  height: 26px; width: 100%;
  padding-left: 6px;
  vertical-align: top;
  background-color: #FFF;
}
.tt-hint {
  width: 100%;
}
.typeahead__clear {
  position: absolute; top: 3px; right: 3px;
  background-color: transparent;
  height: 20px; width: 20px;
  padding: 3px;
  z-index: 102;

  .icon {
    height: 14px; width: 14px;
  }
}

.tt-menu {
  @include dropdown-dropshadow;
  background: #FFF;
  min-width: 100%;
  background: #FFF;
  margin:0; padding: 5px 0;
  z-index: 10;
  position: absolute;
}

.tt-suggestion {
  @include text-ellipsis;
  text-align: left;
  cursor: pointer;
  color: $dark-gray;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  margin: 0; padding: 4px 14px;
  strong {
    font-weight: normal;
  }
  &:last-child {
    border: 0;
  }
  &.rh-suggestion-child {
    color: $medium-gray;
    padding-left: 32px;
  }
}

.tt-suggestion:hover,
.tt-suggestion.tt-cursor {
  background-color: #fff;
  color: $link-hover;
  font-weight: 600;
}

input.text.tt-hint {
  color: $medium-gray;
}
.tt-no-content {
  @include no-bullet;
  color: $medium-gray;
  font-size: 14px;
  padding: 36px 0;
  text-align: center;
}
